import React, { useEffect } from 'react';
import { Box, styled, Badge, Typography, Button, Dialog } from '@mui/material';
// import NotificationsNoneSharpIcon from '@mui/icons-material/NotificationsNoneSharp';
import ParroticaLogo from '../../../assets/ParroticaLogo.svg';
import { FaAngleLeft, FaBars } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { getAuthSelector } from '../../utils/selectors/selectors';
import { useNavigate } from 'react-router-dom';
import { userLSAuth } from '../../app/features/env';
import ProfileMenu from './ProfileMenu';
import CalculatorApp from '../../calculator/CalculatorApp';

const HeaderWrapper = styled(Box)(
   ({ theme }) => `

          height: ${theme.header.height};
          color: ${theme.header.textColor};
          padding: ${theme.spacing(0, 6)};
			top: 0;
			left: 0;
          right: 0;
       
          background-color: #374DA0;
          position: fixed;
          justify-content: space-between;
          width: 100%;
          z-index:100;
  `
);

// notification icon
// const NotificationsBadge = styled(Badge)(
//    ({ theme }) => `

//       .MuiBadge-badge {
//           background-color: ${theme.palette.error.main};
//           color: ${theme.palette.error.contrastText};
//           min-width: 18px;
//           height: 18px;
//           padding: 0;
//       }
//   `
// );

const Header = ({
   getHamState,
   isOpen,
   mobileOpen,
   getMobileHamState,
   getLogOutState,
}) => {
   const selector = useSelector(getAuthSelector);
   const navigate = useNavigate();
   const [openMenu, setOpenMenu] = React.useState(true);
   const [mobileOpenMenu, setMobileOpenMenu] = React.useState(false);
   const [isMorning, setIsMorning] = React.useState('');
   const [isMobile, setIsMobile] = React.useState(false);
   const [calculate, setCalculate] = React.useState(false);
   const [img, setImg] = React.useState('');

   useEffect(() => {
      const currentHour = new Date().getHours();
      if (currentHour >= 5 && currentHour < 12) {
         setIsMorning('Good Morning');
      } else if (currentHour >= 12 && currentHour < 16) {
         setIsMorning('Good Afternoon');
      } else if (currentHour >= 16 && currentHour < 24) {
         setIsMorning('Good Evening');
      } else {
         setIsMorning('Good Night');
      }
   }, []);

   React.useEffect(() => {
      setImg(userLSAuth()?.displayPicture);
   }, [
      selector.avatarUploadeded === 'success' ||
         selector.avatarUploadeded === 'failed',
   ]);

   function handleClick() {
      getHamState(!openMenu);
      setOpenMenu(!openMenu);
   }

   function handleClickMobile() {
      getMobileHamState(!mobileOpenMenu);
      setMobileOpenMenu(!mobileOpenMenu);
   }

   // Define a function to update the window width
   const updateMobileState = () => {
      if (window.innerWidth <= 1120) {
         setIsMobile(true);
      } else {
         setIsMobile(false);
      }
   };

   React.useEffect(() => {
      window.addEventListener('resize', updateMobileState);
      if (window.innerWidth <= 1120) {
         setIsMobile(true);
      } else {
         setIsMobile(false);
      }
      // Clean up the event listener when the component unmounts
      return () => {
         window.removeEventListener('resize', updateMobileState);
      };
   }, [window.innerWidth]);

   return (
      <HeaderWrapper
         display="flex"
         alignItems="center"
         textAlign="right"
         sx={{
            boxShadow: `0px 2px 8px -3px rgb(34 51 84 / 20%), 0px 5px 22px -4px rgb(34 51 84 / 10%)`,
         }}
      >
         <Box display={'flex'} alignItems={'center'}>
            {isMobile ? (
               <Box
                  sx={{
                     cursor: 'pointer',
                     display: {
                        xs: 'inline-block',
                        marginRight: '2rem',
                     },
                     '@media (maxWidth: 570px)': {
                        marginRight: '2rem',
                     },
                  }}
                  onClick={handleClickMobile}
               >
                  
                  {mobileOpen ? (
                     <FaAngleLeft color="white" />
                  ) : (
                     <FaBars color="white" />
                  )}
               </Box>
            ) : (
               <Box
                  sx={{
                     cursor: 'pointer',
                     display: {
                        xs: 'inline-block',
                        marginRight: '2rem',
                     },
                     '@media (maxWidth: 570px)': {
                        marginRight: '2rem',
                     },
                  }}
                  onClick={() => {
                     handleClick();
                  }}
               >
                  {isOpen ? (
                     <FaAngleLeft color="white" />
                  ) : (
                     <FaBars color="white" />
                  )}
               </Box>
            )}
            <img
               src={ParroticaLogo}
               height="32px"
               alt="LOGO"
               style={{
                  cursor: 'pointer',
               }}
               onClick={() => navigate('/dashboard')}
            />
         </Box>

         <Button
  onClick={() => setCalculate(true)}
  sx={{
    display: { xs: "block", sm: "none" }, 
    fontWeight: "700",
    background: "linear-gradient(270deg, #5A8DF4 0%, #1E6EF7 71.67%)",
    padding: "0.5rem",
    fontSize: "0.6rem",
    borderRadius: "16px",
    color: "#fff",
    '&:hover': {
      background: "linear-gradient(270deg, #1E6EF7 0%, #5A8DF4 71.67%)",
    },
  }}
>
  Price Calculator
</Button>




         <Box
            display="flex"
            alignItems="center"
            textAlign="center"
            gap={3}
            sx={{
               display: { xs: "none", sm: "flex" }, 
            }}
         >
           <Typography>
           <Button
  onClick={() => setCalculate(true)}
  sx={{
    fontWeight: "700",
    background: "linear-gradient(270deg, #5A8DF4 0%, #1E6EF7 71.67%)",
    padding:"0.5rem",
    fontSize:"0.9rem",
    borderRadius:"16px",
    color: "#fff", 
    '&:hover': {
      background: "linear-gradient(270deg, #1E6EF7 0%, #5A8DF4 71.67%)", 
    },
  }}
>
  Price Calculator
</Button>

           </Typography>
            {/* <Tooltip arrow title="Notifications">
               <NotificationsBadge badgeContent={4}>
                  <NotificationsNoneSharpIcon
                     sx={{
                        color: 'white',
                     }}
                  />
               </NotificationsBadge>
            </Tooltip> */}
            <Box display="flex" alignItems="center" textAlign="center" gap={1}>
               <Box
                  sx={{
                     '@media(max-width:735px)': {
                        display: 'none',
                     },
                  }}
               >
                  <Typography align="left" fontSize={16} color="#fff">
                     {isMorning}
                  </Typography>
                  <Typography
                     align="left"
                     fontSize={16}
                     fontFamily="'Raleway', 'Montserrat', sans-serif"
                     color="#fff"
                     fontWeight={700}
                  >
                     {userLSAuth()?.fullName ? userLSAuth()?.fullName : ''}
                  </Typography>
               </Box>
               <ProfileMenu getLogOutState={getLogOutState}>
                  <img
                     style={{
                        width: '50px',
                        height: '50px',
                        borderRadius: '50%',
                        // border: '1px solid red',
                     }}
                     src={img}
                     alt="Profile"
                  />
                  
               </ProfileMenu>
            </Box>
         
         </Box>
         <Dialog  maxWidth="md"
      fullWidth
      fullScreen={isMobile}  onClose={() => setCalculate(false)} open={calculate}>
                  <CalculatorApp />
               </Dialog>
               
      </HeaderWrapper>
   );
};

export default Header;


import React, { useState, useEffect } from 'react';
import {
   Card,
   Typography,
   Grid,
   Box,
   Button,
   Dialog,
   DialogTitle,
   DialogContent,
   DialogActions,
   LinearProgress,
   IconButton,
} from '@mui/material';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { openVcf } from '../../../components/app/features/contacts/contactSlice';
import { contactSelector } from '../../../components/utils/selectors/selectors';
import backImg from '../../../assets/back.svg';
import CopyPaste from '../../../assets/copy-paste.svg';
import UserAdd from '../../../assets/user-add.svg';
import ImportLight from '../../../assets/import-light.svg';
import Download from '../../../assets/download.svg';
import progress1 from '../../../assets/progress1.svg';
import progress2 from '../../../assets/progress2.svg';
import progress3 from '../../../assets/progress3.svg';
import Success from '../../../assets/success.png';
import CloseIcon from '@mui/icons-material/Close';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { getBaseUrls } from '../../../components/app/features/env';

const CustomButton = styled(Button)`
   background-color: #374DA0;
   color: white;
   text-transform: none;
   font-weight: 600;
   padding: 6px 30px;
   border-radius: 6px;
   &:hover {
      background-color: #2c44b3;
   }
`;

const AddContact = ({ setDialogState, directText, vcfText, copyPasteText }) => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const selector = useSelector(contactSelector);

   const [selectedOption, setSelectedOption] = useState('');
   const [dialogOpen, setDialogOpen] = useState(false);
   const [fileName, setFileName] = useState(null); 
   const loading = selector.loading === 'pending';
   const success = selector.added

   const steps = ['Select file', 'Verify contacts', 'Preview'];

   

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      console.error('No file selected');
      return;
    }

    setFileName(file.name); 

    const formData = new FormData();
    formData.append('vcf', file);

  dispatch(openVcf(formData.get('vcf')))

  };


   const handleOptionClick = (value) => {
      setSelectedOption(value);
      
   };

   const handleDialogClose = (confirm) => {
      setDialogOpen(false);
      dispatch({ type: 'vcf/resetAdded' })
      if (confirm) {
        
         if (selectedOption === 'VCF') navigate(vcfText);
         if (selectedOption === 'CSV') navigate(vcfText);
      }
   };

   return (
      <>
    <Card
  sx={{
    width: { xs: '100vw', sm: '600px' },
    height: { xs: '100vh', sm: 'auto' },
    padding: { xs: '10px', sm: '30px' },
    backgroundColor: '#EFEEF4',
    borderRadius: { xs: '0', sm: '16px' },
    boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
    overflowY: 'auto',

    top: 0,
    left: 0,
    zIndex: 1300,
    position: 'relative',
  }}
>
  {/* Header */}
  <Box
    sx={{
      margin: '0 auto',
      padding: '0.5rem',
      maxWidth: '100%',
      position: 'relative',
    }}
  >
    <img
      src={backImg}
      alt="back"
      style={{
        position: 'absolute',
        top: '10px',
        left: '10px',
        width: '30px',
        cursor: 'pointer',
      }}
      onClick={() => setDialogState(false)}
    />
    <Typography
      sx={{
        textAlign: 'center',
        fontWeight: '700',
        fontSize: { xs: '1.2rem', sm: '1.5rem' },
        marginBottom: '10px',
      }}
    >
      Choose one option
    </Typography>
  </Box>

  {/* Options Grid */}
  <Grid container spacing={1}>
    {['CopyPaste', 'Direct', 'VCF', 'CSV'].map((option, index) => (
      <Grid item xs={12} sm={6} key={index}>
        <Card
          sx={{
            padding: '10px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            borderRadius: '12px',
            boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
            backgroundColor: selectedOption === option ? '#dce3ff' : 'white',
            height: '100%',
          }}
        >
          <img
            src={
              option === 'CopyPaste'
                ? CopyPaste
                : option === 'Direct'
                ? UserAdd
                : ImportLight
            }
            alt="icon"
            style={{ width: '30px', marginBottom: '5px' }}
          />
          <Typography
            fontWeight="700"
            color="#0B0F20"
            fontSize={14}
            sx={{ marginBottom: '5px' }}
          >
            {option === 'CopyPaste'
              ? 'Paste Contacts'
              : option === 'Direct'
              ? 'Create Contacts'
              : option === 'VCF'
              ? 'Import VCF'
              : 'Import CSV'}
          </Typography>
          <Typography variant="body2" sx={{ margin: '5px 0', color: '#333333CC' }}>
           {option === 'CopyPaste' && 'Input multiple contacts by correctly pasting them directly into the field'}
           {option === 'Direct' && `Manually add individual contacts with their details for instant use.
DND and non-DND contact classification takes a few minutes to complete after contact is saved
`}
           {option === 'VCF' && 'Import saved contacts directly from a VCF file for quick access'}
           {option === 'CSV' && 'Upload bulk contacts using a CSV file. Ensure it matches the provided template'}
          </Typography>
          <CustomButton style={{display:"flex",}} onClick={() => handleOptionClick(option)}>Select</CustomButton>
        </Card>
      </Grid>
    ))}
  </Grid>

  {/* Continue Button */}
  <Box
    sx={{
      marginTop: {xs: '30px', sm:'10px'},
      textAlign: 'center',
      bottom: 0,
      backgroundColor: '#EFEEF4',
      padding: { xs: '5px', sm: '10px' },
    }}
  >
    <Button
      sx={{
        backgroundColor: '#3754db',
        color: 'white',
        textTransform: 'none',
        fontWeight: '600',
        borderRadius: '12px',
        width: '100%',
        padding: '10px',
        fontSize: { xs: '0.8rem', sm: '1rem' },
        marginX: { xs: '0 auto', sm: '0' },
      }}
      disabled={!selectedOption}
      onClick={() => {
        if (selectedOption === 'Direct' || selectedOption === 'CopyPaste') {
          navigate(selectedOption === 'Direct' ? directText : copyPasteText);
        } else if (selectedOption === 'VCF' || selectedOption === 'CSV') {
          setDialogOpen(true);
        }
      }}
    >
      Continue
    </Button>
  </Box>
</Card>



         {/* Dialog */}
         <Dialog
  open={dialogOpen}
  onClose={() => handleDialogClose(false)}
  fullWidth
  fullScreen
  PaperProps={{
    sx: {
      borderRadius: { xs: '0', sm: '16px' }, // No border radius for mobile
      backgroundColor: 'white',
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
      height: { xs: '100vh', sm: '80vh' }, 
      width: { xs: '100wv', sm: '55vw' }, 
      paddingY: { xs: '1rem', sm: '0.1rrem' }, 
      overflowY: 'auto',

    },
  }}
>
<DialogTitle
  sx={{
    textAlign: 'center',
    fontSize: {
      xs: '1rem', // Small screens
      sm: '1.25rem', // Medium screens
    },
    fontWeight: '700',
    marginBottom: '16px',
    borderBottom: '1px solid #E3E3E3',
    padding: { xs: '16px 40px 16px 16px', sm: '24px' }, // Extra padding for the close button space
    position: 'relative', // Positioning for IconButton
  }}
>
  <span style={{ wordBreak: 'break-word', textAlign: 'center' }}>
    Complete the steps to import your contacts through {selectedOption === 'CSV' && 'CSV'} {selectedOption === 'VCF' && 'VCF'}
  </span>
  <IconButton
    aria-label="close"
    onClick={() => handleDialogClose(false)}
    sx={{
      position: 'absolute',
      right: 8,
      top: { xs: 8, sm: 16 }, // Adjust the close button position for smaller screens
    }}
  >
    <CloseIcon />
  </IconButton>
</DialogTitle>

<DialogContent
   sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      padding: { xs: '0px', sm: '24px' },
   }}
>
   {/* Step Indicator */}
   <Box
  sx={(theme) => ({
    [theme.breakpoints.up('lg')]: {
      marginTop: selectedOption === 'CSV' && !success && !loading ? '15rem' : '0',
    },
  })}
>
<Box sx={{
  flexGrow: 1,
  padding: {
    xs: 0,   // padding 0 for extra-small screens (mobile)
    sm: 2    // padding 2 for small screens and up
  }
}} >
      <Grid container spacing={{ xs: 3, lg: 2 }} marginLeft={{ xs: 0, lg: 5 }} justifyContent="center" >
        {/* First Image */}
        <Grid item xs={4} sm={4}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography
              variant="body1"
              gutterBottom
              align="left"
              marginLeft='-2rem'
              sx={{ width: "100%",fontWeight:"700",color:"#333333" }}
            >
              Select file
            </Typography>
            <img
              src={progress1}
              alt="Progress 1"
              style={{
                maxWidth: "100%",
                height: "auto",
                display: "block",
              }}
            />
          </Box>
        </Grid>

        {/* Second Image */}
        <Grid item xs={4} sm={4}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography
              variant="body1"
              gutterBottom
              align="left"
               marginLeft='-2rem'
               sx={{ width: "100%",fontWeight:"700",color:"#33333366" }}
            >
              Verify contacts
            </Typography>
            <img
              src={progress2}
              alt="Progress 2"
              style={{
                maxWidth: "100%",
                height: "auto",
                display: "block",
              }}
            />
          </Box>
        </Grid>

        {/* Third Image */}
        <Grid item xs={4} sm={4}>
          <Box display="flex" flexDirection="column" alignItems="start">
            <Typography
              variant="body1"
              gutterBottom
              align="left"
              marginLeft="-3px"
              sx={{ width: "100%",fontWeight:"700",color:"#33333366" }}
             

              
            >
              Preview
            </Typography>
            <img
              src={progress3}
              alt="Progress 3"
              style={{
                  
                height: "auto",
                display: "block",
                
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>


 
 

       
   </Box>

   {/* File Upload Section */}
   {selectedOption === 'CSV' && <>
   
     <div
  style={{
    justifyContent: 'start',
    display: 'flex',
    alignSelf: 'flex-start',
    marginLeft: '2rem',
    fontWeight: '500',
    color: '#333333B2',
    
  }}
>     
  <span style={{ fontSize: '2rem', fontWeight: 'bold', marginRight: '0.5rem' }}>*</span>
  Option 1: You have our CSV template
</div>
   </>}
 


   <Box
      sx={{
         display: 'flex',
         flexDirection: 'column',
         alignItems: 'center',
         justifyContent: 'center',
         border: '2px solid #E3E3E3',
         padding: '20px',
         borderRadius: '8px',
         textAlign: 'center',
         backgroundColor: '#ffffff',
         width: { xs: '90%', sm: '60%', md: '50rem' },
         maxWidth: '100%',
         margin: '0 auto',
         height: '20rem',
      }}
   >
      <img
         src={ImportLight}
         alt="copy"
         style={{
            marginBottom: '16px',
            padding: '1rem',
            borderRadius: '50%',
            background: '#E6F3FA',
            maxWidth: '80px',
         }}
      />
      {!success && (
         <Typography
            variant="body1"
            sx={{
               fontWeight: '600',
               marginBottom: '8px',
               fontSize: '16px',
            }}
         >
            <span style={{ color: '#333333', fontWeight: '700', fontSize: '1rem' }}>
               Click
            </span>
            <Button
               component="label"
               variant="text"
               sx={{
                  color: '#374DA0',
                  textTransform: 'none',
                  fontWeight: '600',
                  fontSize: 'inherit',
                  padding: 0,
                  textDecoration: 'underline',
                  textDecorationColor: '#374DA0',
                  textUnderlineOffset: '2px',
               }}
            >
               Browse
               <input
                  type="file"
                  accept=".csv,.vcf"
                  hidden
                  onChange={handleFileInputChange}
               />
            </Button>{' '}
            <span style={{ color: '#333333', fontWeight: '700', fontSize: '1rem' }}>
               to upload your {selectedOption === 'CSV' && 'CSV'} {selectedOption === 'VCF' && 'VCF'}  file
            </span>
         </Typography>
      )}

      {success && (
         <Typography
            sx={{
               fontSize: '1rem',
               fontWeight: '600',
               color: '#333333',
               display: 'flex',
               gap: '8px',
               alignItems: 'center',
               padding: '5px 0px',
            }}
         >
            <img src={Success} alt="success" style={{ maxHeight: '24px' }} />
            Upload Complete. Click next to continue.
         </Typography>
      )}

      {loading && (
         <>
            <p>{fileName}</p>
            <LinearProgress
               sx={{
                  marginTop: '16px',
                  alignSelf: 'stretch', // Ensures it spans horizontally
                  width: '100%', // Full width
               }}
            />
         </>
      )}
   </Box>

   {selectedOption === 'CSV' && !success && !loading && (
      <>
         <Typography
            sx={{
               color: '#333333',
               fontSize: '1rem',
               fontWeight: 700,
               margin: '2rem 0px',
            }}
         >
            OR
         </Typography>
         <div
  style={{
    justifyContent: 'start',
    display: 'flex',
    alignSelf: 'flex-start',
    marginLeft: '2rem',
    fontWeight: '500',
    color: '#333333B2',
    
  }}
>
  <span style={{ fontSize: '2rem', fontWeight: 'bold', marginRight: '0.5rem' }}>*</span>
  Option 2: You do not have our CSV template
</div>
         <Box
            sx={{
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'center',
               justifyContent: 'center',
               border: '2px solid #E3E3E3',
               padding: '20px',
               borderRadius: '8px',
               textAlign: 'center',
               backgroundColor: '#ffffff',
               width: { xs: '90%', sm: '60%', md: '50rem' },
               maxWidth: '100%',
               margin: '0 auto',
               height: '20rem',
            }}
         >
         
               <img src={Download} alt="" />
         
            <Typography variant="body1" color="textSecondary" marginBottom={1}>
                <span style={{ color: '#333333', fontWeight: '700', fontSize: '1rem' }}>1. Click</span>
               <Button variant="text" color="primary" style={{fontSize:'1rem',marginBottom:'3px'}}>
                  <a href={`${getBaseUrls().data}/v1/csv-template`}>Download CSV template</a>
               </Button>{' '}
              <span style={{ color: '#333333', fontWeight: '700', fontSize: '1rem' }}> to get our CSV template</span>
            </Typography>
            <Typography style={{ color: '#333333', fontWeight: '700', fontSize: '1rem' }} marginBottom={1}>
               2. Populate it with your contact details and save
            </Typography>
            <Typography variant="body1" color="textSecondary">
                <span style={{ color: '#333333', fontWeight: '700', fontSize: '1rem' }}>
             3.  Click
            </span> 
               <Button
                  component="label"
                  variant="text"
                  sx={{
                     color: '#374DA0',
                     textTransform: 'none',
                     fontWeight: '700',
                     fontSize: 'inherit',
                     padding: 0,
                     textDecoration: 'underline',
                     textDecorationColor: '#374DA0',
                     textUnderlineOffset: '2px',
                  }}
               >
                  Browse
                  <input
                     type="file"
                     accept=".csv,.vcf"
                     hidden
                     onChange={handleFileInputChange}
                  />
               </Button>{' '}
               <span style={{ color: '#333333', fontWeight: '700', fontSize: '1rem' }}>
               to upload and move to the next step
            </span> 
            </Typography>
         </Box>
      </>
   )}
</DialogContent>
<DialogActions
   sx={{
      justifyContent: 'end',
      marginTop: '5px',
      flexDirection: { xs: 'column', sm: 'row' },
      gap: '8px',
   }}
>
   {loading && (
      <Button
         onClick={() => handleDialogClose(false)}
         sx={{
            backgroundColor: 'white',
            color: '#333333',
            textTransform: 'none',
            fontWeight: '600',
            borderRadius: '8px',
            padding: '8px 16px',
            border: '1px solid #E3E3E3',
            width: { xs: '100%', sm: 'auto' },
         }}
      >
         Cancel Upload
      </Button>
   )}
   <Button
      onClick={() => navigate(vcfText)}
      disabled={loading || !success}
      sx={{
         backgroundColor: '#3754db',
         color: 'white',
         textTransform: 'none',
         fontWeight: '600',
         borderRadius: '8px',
         padding: '8px 22px',
         width: { xs: '100%', sm: 'auto' },
      }}
   >
      Next
   </Button>
</DialogActions>

      </Dialog>
      
      </>
   );
};

export default AddContact;




import React from 'react';
import { Link } from 'react-router-dom';
import {
   Box,
   Typography,
   Card,
   MenuItem,
   FormControl,
   Select,
   TextareaAutosize,
   Dialog,
   Menu,
   Button,
   CircularProgress,
} from '@mui/material';

import cost from '../../../assets/cost.svg';
import contactImg from '../../../assets/phonebook3.svg';
import { Container } from './style';
import backImg from '../../../assets/back.svg';
import sendingImg from '../../../assets/sending.gif';
import verticalMenu from '../../../assets/verticalMenu.svg';
import scheduleImg from '../../../assets/schMessage.svg';
import styled from '@emotion/styled';
import ScheduleDate from '../../../components/message/scheduleMessage/ScheduleDate';
import MessageContact from '../messageContact';
import MessageSummary from '../../../components/message/messageHome/MessageSummary';
import GroupSelect from '../../../components/message/select/GroupSelect';
import ContactContent from './ContactContent';
import { useDispatch, useSelector } from 'react-redux';
import { messageSelector } from '../../../components/utils/selectors/selectors';
import GroupContent from './GroupContent';
import {
   draftmessage,
   fetchsenderIDs,
   getMsgCost,
   resetCreated,
   schedulemessage,
   sendmessage,
   selectSenderId,
   resetSelectedContacts,
} from '../../../components/app/features/message/messageSlice';
import Success from './Success';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import NotificationPopup from '../../../components/NotificationPopup/NotificationPopup';
import { userLSAuth } from '../../../components/app/features/env';
import InsufficientSmsDialog from '../../../components/modal/InsufficientUnits';
import { setIn } from 'formik';


const Textarea = styled(TextareaAutosize)(
   ({ theme }) => `
		width: 100%;
		min-height: 150px;
      padding: 10px;
		border: 1px solid #A1A1A1;
		border-radius:5px;
		font-size:1rem;
      background: white;
      color: #49454F;
		margin-top: 2rem;
		&:focus {
      outline: none;
      box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
    },
     `
);
const DropDown = styled(Menu)(
   ({ theme }) => `
	position: absolute;
	right: 0;

& .MuiPopover-paper {
	border-radius:0;
}
& .MuiList-root, & .MuiMenu-list {
	padding:0;
	border-radius:0;
}

	`
);
const Activity = styled(MenuItem)(
   ({ theme }) => `
	min-height:unset;
	padding:5px 0 5px 15px;
 box-sizing: border-box;
 white-space: nowrap;
	width: 160px;
	font-weight:600;
	border-radius:0;
	`
);

const Message = () => {
   const selector = useSelector(messageSelector);
   const dispatch = useDispatch();
   const [anchorEl, setAnchorEl] = React.useState(null);
   const [period, setPeriod] = React.useState(false);
   const [draftOpen, setDraftOpen] = React.useState(false);
   const [add, setAdd] = React.useState(false);
   const [openSummary, setOpenSummary] = React.useState(false);
   const [selectGroup, setSelectGroup] = React.useState(false);
   const [open, setOpen] = React.useState(false);
   const [scheduleSuccess, setScheduleSuccess] = React.useState(false);
   const [schedule, setSchedule] = React.useState(false);
   const [action, setAction] = React.useState('');
   const [text, setText] = React.useState('');
   const [numOfContacts, setNumOfContacts] = React.useState(0);
   const [senderId, setSenderId] = React.useState(selector?.selectedSenderId);
   const [openProgress, setOpenProgress] = React.useState(false);
   const [sendProgress, setSendProgress] = React.useState(false);
   const actions = ['Save to Drafts'];
   const balance = userLSAuth()?.balance;
   const [totalCost, setotalCost] = React.useState('');
   const [insufficient, setInsufficient] = React.useState(false);
   const [maxCharactersPerPage, setMaxCharactersPerPage] = React.useState(160);
   const [messageId, setMessageId] = React.useState();

   const calculatePageCount = (text) => {
      let totalLength = text.length;
      let page = 1;
      while (totalLength > maxCharactersPerPage) {
         page++;
         totalLength -= maxCharactersPerPage;
      }
      return page;
   };


   const handleTextAreaChange = (e) => {
      const { value } = e.target;
      setMaxCharactersPerPage(value.length <= 160 ? 160 : 153);
      setText(value);
   };
   const pageCount = calculatePageCount(text);
   const remainingCharacters =
      maxCharactersPerPage - (text.length % maxCharactersPerPage);

   const handleChange = (event) => {
      setSenderId(event.target.value);
      dispatch(selectSenderId({ senderId: event.target.value }));
   };

   React.useEffect(() => {
      if (!selector.senderIds || selector.senderIds.length < 1) {
         dispatch(fetchsenderIDs());
      }
   }, []);

   React.useEffect(() => {
      if (selector.sent === true) {
         setOpenProgress(false);
         setOpen(true);
      } else {
         setOpen(false);
      }
   }, [selector.sent === true]);
   React.useEffect(() => {
      if (selector.sending === true) {
         setSendProgress(true);
         setOpenProgress(false);
      } else {
         setSendProgress(false);
      }
   }, [selector.sending === true]);

   React.useEffect(() => {
      console.log(selector.loading);
      if (selector.loading === 'pending' && selector.sending !== true) {
         setOpenProgress(true);
      } else {
         setOpenProgress(false);
      }
   }, [selector.loading === 'pending' && selector.sending !== true]);

   React.useEffect(() => {
      if (selector.scheduled === true) {
         setScheduleSuccess(true);
      } else {
         setScheduleSuccess(false);
      }
   }, [selector.scheduled === true]);

   React.useEffect(() => {
      if (action === 'Save to Drafts') {
         setDraftOpen(true);
      }
   }, [action === 'Save to Drafts']);



   React.useEffect(() => {
      const handleBeforeUnload = (event) => {
         event.preventDefault();
         if (text !== '') {
            autoSaveDraft();
         }
      };

      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => {
         if (location.pathname !== '/send-message/message') {
            autoSaveDraft();
         }
         window.removeEventListener('beforeunload', handleBeforeUnload);
      };
   }, [text]);

   const forwardMessage = (values) => {
      dispatch(sendmessage(values))
      .then((response) => {
         setMessageId(response?.payload?.data?.id)
   
      })
   };

   const groupIds = selector?.selectedGroups.map((group) => group.id);
   const contactIds = selector?.selectedContacts.map((contact) => contact.id);
   const numbers = selector?.selectedNumbers;

   const newDate = dayjs(new Date());
   const currentDate = newDate.toISOString();
   const [date, setDate] = React.useState(currentDate);
   const [isDialogOpen, setDialogOpen] = React.useState(false);

   const handleOpenDialog = () => {
      setDialogOpen(true);
   };

   const handleCloseDialog = () => {
      setDialogOpen(false);
   };

   const getTime = (value) => {
      setDate(value);
      setPeriod(false);
   };

   const closePeriod = () => setPeriod(false);
   const draft = () => {
      if (text !== '') {
         dispatch(
            draftmessage({
               content: text,
               senderId: senderId,
               scheduledAt: currentDate,
               contactids: contactIds,
               groupids: groupIds,
               numbers,
            })
         );
         window.history.back();
      } else {
         toast.info('type a message');
      }
   };
   const autoSaveDraft = () => {
      if (text !== '') {
         dispatch(
            draftmessage({
               content: text,
               senderId: senderId,
               scheduledAt: currentDate,
               contactids: contactIds,
               groupids: groupIds,
               numbers,
            })
         );
         window.history.back();
      }
   };

   const handleMenu = (value) => {
      setAction(value);
      setAnchorEl(null);
   };
   const setClose = () => setAnchorEl(false);

   const send = (values) => {
      if (text !== '' && schedule === false) {
         forwardMessage(values);
         setText('');
      } else if (text !== '' && schedule === true) {
         dispatch(schedulemessage(values));
         setText('');
         setSchedule(false);
      } else {
         toast.info('type a message');
      }
   };



   // React.useEffect(() => {
   //    dispatch(
   //       getMsgCost({
   //          content: '',
   //          senderId: senderId,
   //          scheduledAt: date,
   //          contactids: contactIds,
   //          groupids: groupIds,
   //          numbers,
   //       })
   //    );
   // }, [selector?.selectedContacts || selector?.selectedGroups]);

   React.useEffect(() => {
      if (selector.msgCost?.dNDCount || selector.msgCost?.nonDNDCount) {
         setNumOfContacts(
            selector.msgCost.dNDCount + selector.msgCost.nonDNDCount
         );
      } else {
         setNumOfContacts(0);
         setNumOfContacts(0);
      }
   }, [selector.msgCost]);

   const resetInsufficient = () => {
      setInsufficient(false);
   };

   const resetMsgSummary = () => {
      setOpenSummary(false);
   };

   return (
      <Container>
         <NotificationPopup />
         <Card
            sx={{
               width: '90%',
               maxWidth: '655px',
               minHeight: '80vh',
               margin: '0 auto',
               marginTop: '2rem',
               position: 'relative',
               boxShadow:
                  '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
               padding: '20px ',
               background: '#E8E8E8',
            }}
         >
            <Box
               sx={{
                  margin: '0 auto',
                  marginTop: '1rem',
                  border: '1px solid transparent',
                  position: 'relative',
               }}
            >
               <img
                  src={backImg}
                  alt="back"
                  style={{
                     position: 'absolute',
                     width: '40px',
                     cursor: 'pointer',
                     // border: '1px solid',
                  }}
                  onClick={() => {
                     if (text !== '') {
                        setDraftOpen(true);
                     } else {
                        window.history.back();
                     }
                  }}
               />
               <DropDown
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={setClose}
                  MenuListProps={{
                     'aria-labelledby': 'basic-button',
                  }}
               >
                  {actions.map((action, index) => (
                     <Activity
                        key={index}
                        onClick={(e) => handleMenu(e.target.innerText)}
                     >
                        {action}
                     </Activity>
                  ))}
               </DropDown>
               <img
                  src={verticalMenu}
                  alt="back"
                  style={{
                     position: 'absolute',
                     width: '40px',
                     right: 0,
                     filter:
                        'invert(27%) sepia(11%) saturate(7243%) hue-rotate(207deg) brightness(94%) contrast(88%)',
                     cursor: 'pointer',
                  }}
                  onClick={(e) => setAnchorEl(e.currentTarget)}
               />
               <Typography
                  sx={{
                     width: 'max-content',
                     margin: '0 auto',
                     fontWeight: 600,
                     fontSize: 'clamp(1.1rem, 2vw, 1.25rem)',
                     color: '#0F0E0E',
                     marginTop: '3rem',
                  }}
               >
                  Send Message
               </Typography>

               <Typography
                  sx={{
                     margin: '1.5rem 0 0.5rem 0',
                     fontWeight: 600,
                     fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                     color: '#0F0E0E',
                     fontFamily: "'Raleway', 'Montserrat', sans-serif",
                  }}
               >
                  Sender ID
               </Typography>
               <FormControl fullWidth>
                  <Select
                     id="demo-simple-select"
                     value={senderId}
                     onChange={handleChange}
                     sx={{
                        backgroundColor: '#fff',
                        fontWeight: 600,
                        padding: '25px 0 !important',
                        fontFamily: "'Raleway', 'Montserrat', sans-serif",
                        '& .MuiOutlinedInput-notchedOutline': {
                           borderColor: '#A1A1A1',
                        },
                     }}
                  >
                     {selector?.senderIds ? (
                        selector?.senderIds
                           .filter((x) => x.status == 'approved')
                           .map((senderId, index) => (
                              <MenuItem value={senderId?.senderId} key={index}>
                                 {senderId?.senderId}
                              </MenuItem>
                           ))
                     ) : (
                        <MenuItem value={1}>No Sender Ids</MenuItem>
                     )}
                  </Select>
               </FormControl>

               <Box
                  sx={{
                     display: 'flex',
                     justifyContent: 'space-between',
                  }}
               >
                  <Typography
                     sx={{
                        margin: '1.5rem 0 0.5rem 0',
                        fontWeight: 600,
                        fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                        color: '#0F0E0E',
                     }}
                  >
                     Recipients
                  </Typography>
                  <Typography
                     sx={{
                        margin: '1.5rem 0 0 0',
                        fontWeight: 400,
                        color: '#374da0',
                        cursor: 'pointer',
                     }}
                     onClick={() => {
                        dispatch(resetSelectedContacts());
                     }}
                  >
                     Clear
                  </Typography>
               </Box>
               <Box
                  sx={{
                     display: 'flex',
                     justifyContent: 'space-between',
                     position: 'relative',
                  }}
               >
                  <img
                     src={contactImg}
                     alt="icon"
                     style={{
                        width: '30px',
                        position: 'absolute',
                        zIndex: 10,
                        right: '10px',
                        bottom: '50%',
                        transform: 'translateY(50%)',
                        cursor: 'pointer',
                     }}
                     onClick={() => setAdd(true)}
                  />

                  <Box
                     sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignContent: 'flex-start',
                        bgcolor: 'background.paper',
                        maxHeight: 100,
                        border: '1px solid #A1A1A1',
                        minHeight: '60px',
                        background: '#fff',
                        borderRadius: '5px',
                        padding: '10px',
                        paddingRight: '40px',
                        overflow: 'auto',
                        width: '100%',
                        scrollbarWidth: 'thin',
                        scrollbarColor: 'grey',
                        // '&::-webkit-scrollbar': {
                        //    width: 0,
                        //    height: 0,
                        // },
                     }}
                  >
                     {selector?.selectedGroups.length > 0
                        ? selector?.selectedGroups.map((item, id) => (
                           <GroupContent key={id} item={item} />
                        ))
                        : null}
                     {selector.selectedContacts.length > 0
                        ? selector.selectedContacts.map((item, id) => (
                           <ContactContent key={id} item={item} />
                        ))
                        : null}

                     {selector.selectedNumbers.length > 0
                        ? selector.selectedNumbers.map((item, id) => (
                           <ContactContent key={id} item={{ number: item }} />
                        ))
                        : null}
                  </Box>
               </Box>

               <Typography
                  sx={{
                     margin: '1rem 0',
                     display: 'flex',
                     fontWeight: 600,
                     color: '#374da0',
                     cursor: 'pointer',
                     width: 'max-content',
                  }}
                  onClick={() => {
                     dispatch(
                        getMsgCost({
                           content: text,
                           senderId: senderId,
                           scheduledAt: date,
                           contactids: contactIds,
                           groupids: groupIds,
                           numbers,
                        })
                     );
                     setOpenSummary(true);
                  }}
               >
                  <img src={cost} alt="icon" style={{ marginRight: '10px' }} />
                  View Pricing Info
               </Typography>

               <Typography
                  sx={{
                     fontSize: '1rem',
                     color: '#555',
                     display: 'inline',
                     fontWeight: 600,
                     mt: 2,
                  }}
               >
                  Avoid use of{' '}
                  <strong style={{ fontWeight: 800, fontSize: '1.3rem' }}>
                     "&"
                  </strong>{' '}
                  as it truncates the message.{' '}
               </Typography>

               <Link
                  to="/send-message/rules"
                  style={{ textDecoration: 'none' }}
               >
                  <Typography
                     sx={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        color: '#1E90FF',
                        display: 'inline',
                        fontSize: '1.05rem',
                        fontWeight: 600,
                     }}
                  >
                     Learn more...
                  </Typography>
               </Link>

               <Textarea
                  margin="normal"
                  name="desc"
                  aria-label="about"
                  minRows={1}
                  // placeholder='Enter message here...'
                  onChange={handleTextAreaChange}
                  value={text}
                  variant="outlined"
                  id="group_desc"
               />

               <Box
                  sx={{
                     display: 'flex',
                     justifyContent: 'space-between',
                     marginBottom: '2rem',
                  }}
               >
                  <Typography
                     sx={{
                        width: 'max-content',
                        color: '#374DAO',
                        fontWeight: '600',
                     }}
                  >
                     {remainingCharacters}/{pageCount}
                  </Typography>
                  <Typography
                     sx={{
                        width: 'max-content',
                        color: '#374DAO',
                        fontWeight: '600',
                     }}
                  >
                     {`contacts:`}
                     {numOfContacts}
                  </Typography>
               </Box>

               <Button
                  disabled={text.length === 0}
                  sx={{
                     width: '300px',

                     margin: '1.5rem auto',
                     borderRadius: '8px',
                     backgroundColor: '#374DA0',
                     color: '#fff',
                     display: 'flex',
                     justifyContent: 'center',
                     ':hover': {
                        backgroundColor: '#374DA0',
                     },
                     '@media(max-width:500px)': {
                        width: '100%',
                     },
                  }}
               >
                  <Typography
                     sx={{
                        textAlign: 'center',
                        width: '80%',
                        padding: '10px',
                        cursor: 'pointer',

                     }}
                     onClick={async () => {
                        try {

                           await dispatch(
                              getMsgCost({
                                 content: text,
                                 senderId: senderId,
                                 scheduledAt: date,
                                 contactids: contactIds,
                                 groupids: groupIds,
                                 numbers,
                              })
                           );

                           await send({
                              content: text,
                              senderId: senderId,
                              scheduledAt: date,
                              contactids: contactIds,
                              groupids: groupIds,
                              numbers,
                           });

                           const totalCost = selector.msgCost?.totalCost || 0;
                           const userBalance = userLSAuth()?.balance || 0;

                           if (totalCost > userBalance) {
                              setInsufficient(true);
                           }
                        } catch (error) {
                           console.error('An error occurred:', error);
                        }
                     }}
                  >
                     Send Message
                  </Typography>

                  {/* <Typography
                     sx={{
                        width: '20%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderLeft: '1px solid #fff',
                        padding: '10px',
                        cursor: 'pointer',
                     }}
                     onClick={() => {
                        setPeriod(true);
                        setSchedule(true);
                     }}
                  >
                     <img src={scheduleImg} alt="icon" />
                  </Typography> */}
               </Button>
               <Dialog onClose={() => setPeriod(false)} open={period}>
                  <ScheduleDate getTime={getTime} closePeriod={closePeriod} />
               </Dialog>
               <Dialog
                  onClose={() => {
                     setAction('');
                     setDraftOpen(false);
                  }}
                  open={draftOpen}
               >
                  <Card sx={{ padding: '20px 55px' }}>
                     <Typography
                        sx={{
                           textAlign: 'center',
                           marginBottom: '2rem',
                           fontWeight: 600,
                           fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                        }}
                     >
                        Save this message as a draft?
                     </Typography>
                     <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography
                           sx={{
                              fontWeight: 600,
                              fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                              color: '#374DAO',
                              marginRight: '1rem',
                              cursor: 'pointer',
                           }}
                           onClick={() => draft()}
                        >
                           Save as Draft
                        </Typography>
                        <Typography
                           sx={{
                              fontWeight: 600,
                              fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                              color: '#374DAO',
                              cursor: 'pointer',
                           }}
                           onClick={() => window.history.back()}
                        >
                           Discard
                        </Typography>
                     </Box>
                  </Card>
               </Dialog>
               <Dialog onClose={() => setAdd(false)} open={add}>
                  <MessageContact
                     contactText={'/send-message/select-contact'}
                     groupsText={'/send-message/select-group'}
                     pasteText={'/send-message/select-paste'}
                  />
               </Dialog>
               <Dialog onClose={() => setSelectGroup(false)} open={selectGroup}>
                  <GroupSelect />
               </Dialog>
               <Dialog onClose={() => setOpenSummary(false)} open={openSummary}>
                  <MessageSummary resetMsgSummary={resetMsgSummary} />
               </Dialog>
               <Dialog
                  onClose={() => {
                     setOpen(false);
                     dispatch(resetCreated());
                  }}
                  open={open}
               >
                  <Success id={messageId} msgType="Message Sent!" />
               </Dialog>


               <Dialog
                  onClose={() => setScheduleSuccess(false)}
                  open={scheduleSuccess}
               >
                  <Success msgType="Message Scheduled Successfully!" />
               </Dialog>

               <Dialog
                  onClose={() => setInsufficient(false)}
                  open={insufficient}
               >
                  <InsufficientSmsDialog
                     resetInsufficient={resetInsufficient}
                  />
               </Dialog>

               <Dialog
                  onClose={() => {
                     setOpenProgress(false);
                  }}
                  open={openProgress}
               >
                  <div
                     style={{
                        background: '#555',
                        overflowX: 'hidden',
                        overflowY: 'hidden',
                        background: 'none',
                        padding: '10px',
                        backgroundColor: '#374DA0',
                     }}
                  >
                     <CircularProgress
                        style={{
                           color: 'white',
                        }}
                     />
                  </div>
               </Dialog>




               <Dialog
                  onClose={() => {
                     setSendProgress(false);
                  }}
                  open={sendProgress}
               >
                  <div
                     style={{

                        padding: '10px',

                        textAlign: 'center',
                     }}
                  >
                     <img
                        src={sendingImg}
                        alt="Sending"
                        style={{
                           width: '100%',
                           maxWidth: '25rem',
                           height: 'auto',
                           padding: '0 2rem'
                        }}

                     />
                     <Box
                        sx={{
                           display: "flex",
                           justifyContent: "center",
                           alignItems: "center",
                           fontWeight: "bold",
                           fontSize: {
                              xs: "1rem",
                              sm: "1.25rem",
                              md: "1.5rem",
                           },
                           textAlign: "center",
                           padding: {
                              xs: "1.5rem",
                              sm: "2rem",
                              md: "3rem",
                           },
                        }}
                     >
                        Sending Message...
                     </Box>


                  </div>
               </Dialog>

            </Box>
         </Card>
      </Container>
   );
};

export default Message;

import React from 'react';
import Flickity from 'react-flickity-component';
import 'flickity/css/flickity.css';
import adOne from '../../assets/Advert 5.svg';
import adTwo from '../../assets/Advert 3.svg';
import adThree from '../../assets/Advert 2.svg';
import Delivery from '../../assets/AdvertDelivery.png';
import unit from '../../assets/AdvertUnit.png';
// import adFive from '../../assets/Advert 1.svg';
import adFour from '../../assets/Advert 4.svg';
import './style.css';
import { useNavigate } from 'react-router-dom';

const flickityOptions = {
   initialIndex: 1,
   contain: true,
   imagesLoaded: true,
   wrapAround: true,
   friction: 0.25,
   accessibility: true,
   selectedAttraction: 0.01,
   cellAlign: 'left',
   autoPlay: true,
   
};

const Slider = ({ handleOpen }) => {
   const navigate = useNavigate();
   return (
      <Flickity
         className={'carousel'}
         elementType={'div'}
         options={flickityOptions}
         disableImagesLoaded={false}
         reloadOnUpdate
         static
         
      >
         <img
            src={adOne}
            onClick={() => navigate('/send-message/verify')}
            alt="icon"
            className="carousel-cell"
         />
         <img
            src={adThree}
            onClick={() => navigate('/send-message/rules')}
            alt="icon"
            className="carousel-cell"
         />
         <img
            src={adTwo}
            onClick={() => navigate('/top-up/pricing-info')}
            alt="icon"
            className="carousel-cell"
         />
         <img
            src={adFour}
            onClick={() => navigate('/send-message/schedule-message')}
            alt="icon"
            className="carousel-cell"
         />
         <img
            src={Delivery}
            onClick={() => navigate('/send-message/history')}
            alt="icon"
            className="carousel-cell"
            style={{width:'350px'}}
         />
         <img
            src={unit}
            onClick={() => {
               handleOpen(); 
        
             }}
            alt="icon"
            className="carousel-cell"
            style={{width:'350px'}}
         />
      </Flickity>
   );
};

export default Slider;

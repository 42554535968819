import { Card, Box, Typography, Button } from '@mui/material';
import successImg from '../../../assets/message-sent.png';
import React from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
// import { messageSelector } from '../../../components/utils/selectors/selectors';
import { resetCreated } from '../../../components/app/features/message/messageSlice';
import { useNavigate } from 'react-router-dom';

export const FlatButton = styled(Button)(
   ({ theme }) => `
background: #374DA0;
color: #fff;
font-weight: 600;
width: 60%;
text-align: center;
font-size: 0.85rem;
border-radius:14px;
margin: 0 auto;
margin-top: 3rem;
&:hover {background: #374DA0;}
`
);

const Success = (props) => {
   // const selector = useSelector(messageSelector);
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const {id, msgType = 'Message Sent!' } = props;
   return (
      <Card
         sx={{
            width: {
               xs: '100%', 
               sm: '300px', 
               md: '400px', 
            },
            padding: '2rem 0',
            display: 'flex',
            flexDirection: 'column',
         }}
      >
         <Box
            sx={{
               margin: 'auto',
               width: '150px',
               marginTop: '1rem',
               marginBottom: '2rem',
            }}
         >
            <img src={successImg} alt="success" width={'100%'} />
         </Box>
        
         <Typography
            sx={{
               textAlign: 'center',
               marginTop: '0.5rem',
               fontWeight: '700',
               fontSize: '1.5rem',
            }}
         >
            {msgType}
         </Typography>
         <Typography
            sx={{
               textAlign: 'center',
               marginTop: '0.5rem',
               fontWeight: '400',
               fontSize: '1rem',
               paddingX:'1rem'
            }}
         >
            {msgType === 'Message Sent!' && (
               <div>
                   You can view the progress of your campaign on your dashboard.
               </div>
            )}
         </Typography>
         <FlatButton
         
            onClick={() => {
               dispatch(resetCreated());
               if (msgType === 'Message Scheduled Successfully!') {
                  navigate('/send-message/schedule');
               } else if (msgType === 'Message Sent!') {
                  navigate( `/send-message/history/${id}`);
               }
            }}
         >
            {msgType === 'Message Scheduled Successfully!' && (
               <div>
                   Okay
               </div>
            )}

{msgType === 'Message Sent!' && (
               <div>
                  View Delivery Report
               </div>
            )}
         </FlatButton>
         {/* <Button
            variant="outlined"
            sx={{
               fontWeight: 600,
               width: '50%',
               textAlign: 'center',
               fontSize: '0.85rem',
               borderRadius: '5px',
               margin: '0 auto',
               marginTop: '1rem',
            }}
         >
            View Delivery Report
         </Button> */}
      </Card>
   );
};

export default Success;

import React from 'react';
import {
   Box,
   Typography,
   Card,
   MenuItem,
   Menu,
   Button,
   Dialog,
   Tooltip,
   IconButton,
   Grid,
} from '@mui/material';
import styled from '@emotion/styled';
import { Container } from './style';
import backImg from '../../../assets/back.svg';
import verticalMenu from '../../../assets/verticalMenu.svg';
import groupIcon from '../../../assets/group.svg';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { messageSelector } from '../../../components/utils/selectors/selectors';
import {
   archiveMessages,
   deleteMessage,
   getSingleMessage,
   getUserMessages,
   // sendExistingMessage,
} from '../../../components/app/features/message/messageSlice';
import StatusMeaning from '../../../components/modal/StatusMeaning';
import PieChart from '../../../components/chart/PieChartCom';
// import dayjs from 'dayjs';

const DropDown = styled(Menu)(
   ({ theme }) => `
	position: absolute;
	right: 0;

& .MuiPopover-paper {
	border-radius:0;
}
& .MuiList-root, & .MuiMenu-list {
	// padding:0;
	border-radius:0;
}

	`
);
const Activity = styled(MenuItem)(
   ({ theme }) => `
	min-height:unset;
	padding:5px 0;
 box-sizing: border-box;
 white-space: nowrap;
	width: 160px;
	font-weight:600;	
	border-radius:0;
	`
);
// const FlatButton = styled(Button)(
//    ({ theme }) => `
// 	background-color:#fff;
//  font-size:0.9rem;
// 	font-weight:500;
// 	font-family: 'Roboto', sans-serif;
// 	border-radius:0;
//  width: 45%;
//  border-radius: 8px;
// 	`
// );

const HistoryDetails = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const selector = useSelector(messageSelector);
   const [isDelete, setIsDelete] = React.useState(false);
   const [action, setAction] = React.useState('');
   const [anchorEl, setAnchorEl] = React.useState(null);
   const [isArchive, setIsArchive] = React.useState(false);
   const setClose = () => setAnchorEl(false);
   const [value, setValue] = React.useState('');
   const [isCopied, setIsCopied] = React.useState(false);
   const [Delivery, setDelivery] = React.useState('');

   const handleChange = (value) => {
      setAction(value);
      setAnchorEl(null);
   };

   const handleCopyClick = () => {
      navigator.clipboard.writeText(value).then(() => {
         setIsCopied(true);
         setTimeout(() => {
            setIsCopied(false);
         }, 2000);
      });
   };

   // const resendMsg = () => {
   //    const newDate = dayjs(new Date());
   //    const currentDate = newDate.toISOString();
   //    if (selector.message) {
   //       const contactIds = [];
   //       const groupIds = [];
   //       if (selector.message.contacts) {
   //          selector.message.contacts.map((item) => contactIds.push(item.id));
   //       }
   //       if (selector.message.groups) {
   //          selector.message.groups.map((item) => groupIds.push(item.id));
   //       }
   //       dispatch(
   //          sendExistingMessage({
   //             id: params.id,
   //             msg: {
   //                content: selector.message.content,
   //                senderId: selector.message.senderId,
   //                scheduledAt: currentDate,
   //                contactids: contactIds,
   //                groupids: groupIds,
   //             },
   //          })
   //       );
   //    }
   // };

   const actions = ['Archive', 'Resend Message', 'Delete'];
   const params = useParams();

   const handleValueChange = (event) => {
      setValue(event.target.value);
   };

   React.useEffect(() => {
      dispatch(getSingleMessage({ id: params.id }));
   }, [params.id]);

   React.useEffect(() => {
      if (selector?.message.content) {
         setValue(selector?.message?.content);
         setDelivery(selector?.message?.deliveryReport);
      }
   }, [selector?.message?.content]);

   React.useEffect(() => {
      if (action === 'Delete') {
         setIsDelete(true);
      } else if (action === 'Archive') {
         setIsArchive(true);
      } else if (action === 'Resend Message') {
         navigate(`/send-message/drafts-edit/${params.id}`);
      }
   }, [action]);

   const date = new Date(selector?.message.createdAt);
   const formattedDate = date.toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
   });

   const groupCount = selector?.message?.groups
      ? Number(selector?.message.groups.map((item) => item.contacts.length))
      : 0;
   const contactCount = selector.message?.contacts
      ? groupCount + selector.message?.contacts.length
      : 0;

   const deleteItem = () => {
      dispatch(
         deleteMessage({
            ids: String(selector.message.id && selector.message.id),
         })
      );
      dispatch(getUserMessages());
      setAction('');
      setIsDelete(false);
      window.history.back();
   };
   const archiveMsg = () => {
      dispatch(archiveMessages([selector.message.id && selector.message.id]));
      dispatch(getUserMessages());
      setAction('');
      setIsArchive(false);
      window.history.back();
   };

   const data = [
      { name: 'Sent', value: Delivery?.sent },
      { name: 'Delivered', value: Delivery?.delivered },
      { name: 'Rejected', value: Delivery?.rejected },
      { name: 'Failed', value: Delivery?.failed },
   ];

   const Total = Delivery?.total;

   return (
      <Container>
         <Card
            sx={{
               minHeight: '80vh',
               margin: '0 auto',
               marginTop: '2rem',
               position: 'relative',
               marginX: { xs: 0, sm: 1, md: 1, lg:3,xl:6 },

               padding: { xs: '8px', sm: '10px ', md: '20px ' },
               background: '#E8E8E8' ,
            }}
            elevation={0}
         >
            <Box
               sx={{
                  margin: '0 auto',
                  marginTop: '1rem',
                  border: '1px solid transparent',
                  position: 'relative',
               }}
            >
               <Dialog
                  onClose={() => {
                     setIsDelete(false);
                     setAction('');
                  }}
                  open={isDelete}
               >
                  <Card sx={{ padding: '20px 30px ' }}>
                     <Typography
                        sx={{
                           fontWeight: '600',
                           fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                           textAlign: 'center',
                           marginBottom: '2rem',
                        }}
                     >
                        Are you sure you want to delete the selected messages?
                     </Typography>
                     <Box
                        sx={{
                           display: 'flex',
                           justifyContent: 'center',
                        }}
                     >
                        <Typography
                           sx={{
                              fontWeight: '600',
                              fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                              cursor: 'pointer',
                              marginRight: ' 1rem',
                           }}
                           onClick={() => {
                              setIsDelete(false);
                              setAction('');
                           }}
                        >
                           Cancel
                        </Typography>
                        <Typography
                           sx={{
                              fontWeight: '600',
                              fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                              color: 'red',
                              cursor: 'pointer',
                           }}
                           onClick={() => deleteItem()}
                        >
                           Yes
                        </Typography>
                     </Box>
                  </Card>
               </Dialog>
               <Dialog
                  onClose={() => {
                     setIsArchive(false);
                     setAction('');
                  }}
                  open={isArchive}
               >
                  <Card sx={{ padding: '20px 30px ' }}>
                     <Typography
                        sx={{
                           fontWeight: '600',
                           fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                           textAlign: 'center',
                           marginBottom: '2rem',
                        }}
                     >
                        Archive selected messages?
                     </Typography>
                     <Box
                        sx={{
                           display: 'flex',
                           justifyContent: 'center',
                        }}
                     >
                        <Typography
                           sx={{
                              fontWeight: '600',
                              fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                              cursor: 'pointer',
                              marginRight: ' 1rem',
                           }}
                           onClick={() => {
                              setIsArchive(false);
                              setAction('');
                           }}
                        >
                           Cancel
                        </Typography>
                        <Typography
                           sx={{
                              fontWeight: '600',
                              fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                              color: 'red',
                              cursor: 'pointer',
                           }}
                           onClick={() => archiveMsg()}
                        >
                           Yes
                        </Typography>
                     </Box>
                  </Card>
               </Dialog>
               <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                     width: '100%',
                     height: '100%',
                  }}
               >
                  <Box
                     component="img"
                     src={backImg}
                     alt="back"
                     onClick={() => window.history.back()}
                     sx={{
                        width: '40px',
                        cursor: 'pointer',
                        marginRight: 1,
                     }}
                  />
             <Box 
  sx={{ 
    display: { xs: 'block', sm: 'flex' }, 
    justifyContent: 'space-between', 
    width: '100%', 
    alignItems: 'center' 
  }}
>
  <Box 
    sx={{ 
      width: '100%', 
      display: { xs: 'block', sm: 'flex' } 
    }}
  >
    <Typography
      variant="body1"
      sx={{ 
         fontSize: { xs: '0.8rem', sm: '1.6rem' }, 
         fontWeight: { xs: 500, sm: 700 } 
       }}
    >
      {selector.message.senderId} -{' '}
    </Typography>
    <Typography
      variant="body1"
      sx={{ 
         fontSize: { xs: '0.8rem', sm: '1.6rem' }, 
         fontWeight: { xs: 500, sm: 700 } 
       }}
    >
      {' '}
      {formattedDate}
    </Typography>
  </Box>
  <Box>
    <StatusMeaning />
  </Box>
</Box>

                 
                  
               </Box>

               <DropDown
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={setClose}
                  MenuListProps={{
                     'aria-labelledby': 'basic-button',
                  }}
               >
                  {actions.map((action, index) => (
                     <Activity
                        key={index}
                        onClick={(e) => handleChange(e.target.innerText)}
                     >
                        {action}
                     </Activity>
                  ))}
               </DropDown>

               {/* <img
                  src={verticalMenu}
                  alt="back"
                  style={{
                     position: 'absolute',
                     width: '40px',
                     right: 0,
                     filter:
                        'invert(27%) sepia(11%) saturate(7243%) hue-rotate(207deg) brightness(94%) contrast(88%)',
                     cursor: 'pointer',
                  }}
                  onClick={(e) => setAnchorEl(e.currentTarget)}
               /> */}

               <Typography
                  sx={{
                     width: 'max-content',
                     margin: '0 auto',
                     fontWeight: 600,
                     fontSize: '0.9rem',
                     color: '#0F0E0E',
                     fontFamily: "'Raleway', 'Montserrat', sans-serif",
                  }}
               >
       
               </Typography>
               <Typography
                  sx={{
                     fontWeight: 600,
                     fontSize: '0.9rem',
                     color: '#374DA0',
                     marginTop: '2rem',
                     display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'space-between',
                     position: 'relative',
                  }}
               >
                  {/* <StatusMeaning /> */}
                
               </Typography>
                
               <Box
  sx={{
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row' },
    height: { xs: '700px', sm: '400px' },
  }}
>
  {/* Left section */}
  <Box
    sx={{
      flex: 1,
      height: { xs: '300px', sm: '95%' },
      marginBottom: { xs: 2, sm: 0 },
    }}
  >
    <Card elevation={0} sx={{ height: '100%', width: '100%' }}>
      <PieChart total={Total} data={data} />
    </Card>
  </Box>

  {/* Right section */}
  <Box
    sx={{
      flex: 1,
      height: '100%',
      marginLeft: { xs: 0, sm: 1 },
    }}
  >
    <Grid
      container
      spacing={2}
      sx={{
        height: '100%',
        fontWeight: '500',
      }}
    >
      {[
        { label: 'Sent SMS', value: Delivery.sent },
        { label: 'Delivered', value: Delivery.delivered },
        { label: 'Rejected', value: Delivery.rejected },
        { label: 'Failed', value: Delivery.failed },
      ].map((item, index) => (
        <Grid
          key={index}
          item
          xs={6}
          sm={6}
          sx={{
            height: { xs: 'auto', sm: '50%' },
            textAlign: 'center',
          }}
        >
          <Card
            elevation={0}
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: { xs: 2, sm: 0 },
            }}
          >
            <Typography
              sx={{
                fontSize: '24px',
                color: '#0F0E0E',
                fontWeight: 500,
              }}
            >
              {item.value}
            </Typography>
            <Typography
              sx={{
                fontSize: '16px',
                color: '#555',
                fontWeight: 500,
              }}
            >
              {item.label}
            </Typography>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Box>
</Box>



               <Box
                  sx={{
                     display: 'grid',
                     gridTemplateColumns: {
                        xs: '1fr',
                        sm: '1fr 1fr',
                     },
                     gridAutoFlow: {
                        xs: 'row',
                        sm: 'dense',
                     },
                     columnGap: 1,
                     rowGap: 2,
                     
                  }}
               >
                  <Box sx={{ marginTop: '1rem' }}>
                     <Box
                        sx={{
                           display: 'flex',
                           justifyContent: 'space-between',
                           padding: '10px 15px',
                           backgroundColor: '#374DA0',
                           color: '#fff',
                           borderTopLeftRadius: '10px',
                           borderTopRightRadius: '10px',
                          
                        }}
                     >
                        <Typography
                           sx={{ fontSize: '1rem', fontWeight: '700' }}
                        >
                           Recipient’s Phone Number
                        </Typography>
                        <Typography
                           sx={{ fontSize: '1rem', fontWeight: '700' }}
                        >
                           Status
                        </Typography>
                     </Box>
                     <Box sx={{
                          maxHeight: '300px',
                          overflowY: 'auto',
                     }}>
   {selector?.message?.contacts
                        ? selector?.message?.contacts.map((item, indx) => (
                             <Card
                                sx={{
                                   display: 'flex',
                                   padding: '10px 15px',
                                   marginBottom: '10px',
                                   alignItems: 'center',
                                   justifyContent: 'space-between',
                                }}
                                key={indx}
                                elevation={0}
                             >
                                <Box
                                   sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                   }}
                                >
                                   <Box
                                      sx={{
                                         width: '40px',
                                         height: '40px',
                                         borderRadius: '50%',
                                         backgroundColor: ' #D7DBEC',
                                         display: 'flex',
                                         alignItems: 'center',
                                         justifyContent: 'center',
                                      }}
                                   >
                                      <Typography
                                         sx={{
                                            fontWeight: '600',
                                            fontSize:
                                               'clamp(1rem, 1.5vw, 1.1rem)',
                                         }}
                                      >
                                         {item?.fullName
                                            ?.split('')[0]
                                            ?.toUpperCase()}
                                      </Typography>
                                   </Box>
                                   <Box sx={{ paddingLeft: '15px' }}>
                                      <Typography
                                         sx={{
                                            fontWeight: '600',
                                            fontSize:
                                               'clamp(0.9rem, 1.5vw, 1rem)',
                                         }}
                                      >
                                         {item.fullName}
                                      </Typography>
                                      <Typography
                                         sx={{
                                            fontWeight: '600',
                                            fontSize:
                                               'clamp(0.9rem, 1.5vw, 1rem)',
                                         }}
                                      >
                                         {item.number}
                                      </Typography>
                                   </Box>
                                </Box>
                                {item.deliveryStatus != null && (
                                   <Typography
                                      sx={{
                                         fontWeight: '600',
                                         fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                                         color:
                                            item.deliveryStatus === 'Delivered'
                                               ? '#0FA958'
                                               : item.deliveryStatus ===
                                                 'Failed'
                                               ? '#d6040f'
                                               : '#4032a8',
                                      }}
                                   >
                                      {item.deliveryStatus}
                                   </Typography>
                                )}
                             </Card>
                          ))
                        : null}
                     {contactCount === 0 && (
                        <Typography
                           sx={{
                              color: '#374da0',
                              fontWeight: '600',
                              textAlign: 'center',
                              fontSize: '1rem',
                           }}
                        >
                           No Added Contacts
                        </Typography>
                     )}

                     <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100vh"
                     ></Box>

                     {selector?.message?.groups &&
                        selector?.message?.groups.map((group) =>
                           group.contacts.map((contact, indx) => (
                              <Card
                                 sx={{
                                    display: 'flex',
                                    padding: '10px 15px',
                                    marginBottom: '10px',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                 }}
                                 key={indx}
                                 elevation={0}
                              >
                                 <Box
                                    sx={{
                                       display: 'flex',
                                       alignItems: 'center',
                                    }}
                                    elevation={0}
                                 >
                                    <Box
                                       sx={{
                                          width: '40px',
                                          height: '40px',
                                          borderRadius: '50%',
                                          backgroundColor: ' #D7DBEC',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                       }}
                                       elevation={0}
                                    >
                                       <Typography
                                          sx={{
                                             fontWeight: '600',
                                             fontSize:
                                                'clamp(1rem, 1.5vw, 1.1rem)',
                                          }}
                                       >
                                          {contact.fullName
                                             ?.split('')[0]
                                             ?.toUpperCase()}
                                       </Typography>
                                    </Box>
                                    <Box sx={{ paddingLeft: '15px' }}>
                                       <Typography
                                          sx={{
                                             fontWeight: '600',
                                             fontSize:
                                                'clamp(0.9rem, 1.5vw, 1rem)',
                                          }}
                                       >
                                          {contact.fullName}
                                       </Typography>
                                       <Typography
                                          sx={{
                                             fontWeight: '600',
                                             fontSize:
                                                'clamp(0.9rem, 1.5vw, 1rem)',
                                          }}
                                       >
                                          {contact.number}
                                       </Typography>
                                    </Box>
                                 </Box>
                                 {contact.deliveryStatus != null && (
                                    <Typography
                                       sx={{
                                          fontWeight: '600',
                                          fontSize:
                                             'clamp(0.9rem, 1.5vw, 1rem)',
                                          color:
                                             contact.deliveryStatus ===
                                             'Delivered'
                                                ? '#0FA958'
                                                : contact.deliveryStatus ===
                                                  'Failed'
                                                ? '#d6040f'
                                                : '#4032a8',
                                       }}
                                    >
                                       {contact.deliveryStatus}
                                    </Typography>
                                 )}
                              </Card>
                           ))
                        )}
                  </Box>

                     </Box>
                  <div>
                  <Box
                        sx={{
                           display: 'flex',
                           justifyContent: 'space-between',
                           padding: '5px 15px',
                           backgroundColor: 'white',
                           color: '#0F0E0E',
                           borderTopLeftRadius: '10px',
                           borderTopRightRadius: '10px',
                           alignItems: 'center',
                           marginTop: '1rem',
                          
                        }}
                     >
                        <Typography
                           sx={{ fontSize: '1rem', fontWeight: '500',color: '#0F0E0E' }}
                        >
                          Campaign Message
                        </Typography>
                        <Typography
                           sx={{ fontSize: '1rem', fontWeight: '700' }}
                        >
                            <Tooltip title="Copy Message Content">
                     <IconButton onClick={() => handleCopyClick()}>
                        <ContentCopyIcon />
                     </IconButton>
                  </Tooltip>
                  {isCopied && (
                     <Typography
                        sx={{
                           position: 'absolute',
                           fontSize: 'clamp(0.9rem, 1.5vw, 1rem)',
                           right: 0,
                           bottom: '-10px',
                        }}
                     >
                        copied!
                     </Typography>
                  )}
                        </Typography>
                     </Box>
                       <textarea
                     elevation={0}
                     value={value}
                     onChange={handleValueChange}
                     style={{
                        outline: 'none',
                     
                        width: '100%',
                        minHeight: '15rem',
                        maxHeight: 'fit-content',
                        lineHeight: 1.4,
                        
                        borderBottomLeftRadius: '8px',
                        borderBottomRightRadius: '8px',
                        padding: '8px 15px',
                        fontWeight: 500,
                        fontSize: '1rem',
                        color: '#0F0E0E',
                        background: '#fff',
                        resize: 'none',
                     }}
                     disabled
                  />
                  </div>
             
                
               </Box>
            </Box>
         </Card>
      </Container>
   );
};

export default HistoryDetails;

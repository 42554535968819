import React, { useState } from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer, Legend } from 'recharts';

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
    total,
  } = props;

  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text
        x={cx}
        y={cy - 10}
        dy={8}
        textAnchor="middle"
        fill="#0F0E0E"
        fontSize="1.5rem"
        fontWeight="700"
      >
        {total}
      </text>
      <text x={cx} y={cy + 20} textAnchor="middle" fill="#555" fontSize="1rem" fontWeight="600">
        Total Messages
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
        fontWeight="500"
      >{`${payload.name}: ${value}`}</text>
    </g>
  );
};

const PieChartCom = ({ data, total }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const colors = ['#0A5FFD', '#34C759', '#FF9500', '#E50707'];

  const coloredData = data.map((entry, index) => ({
    ...entry,
    fill: colors[index % colors.length],
  }));

  return (
    <ResponsiveContainer width="100%" height={350}>
    <PieChart margin={{ top: 10, bottom: 20 }}>
      <Pie
        activeShape={(props) => renderActiveShape({ ...props, total })}
        data={coloredData}
        cx="50%"
        cy="45%" 
        activeIndex={activeIndex}
        innerRadius={100}
        outerRadius={120}
        dataKey="value"
        onMouseEnter={onPieEnter}
      />
      <Legend
        layout="horizontal"
        verticalAlign="bottom"
        align="center"
        wrapperStyle={{
          fontSize: '0.8rem',
         fontWeight:'400',
         

        }}
        formatter={(value) => (
          <span style={{ color: '#0F0E0E',fontWeight:'400' }}>{value}</span> 
        )}
      />
    </PieChart>
  </ResponsiveContainer>
  );
};

export default PieChartCom;

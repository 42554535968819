import './App.css';
import Home from './pages/home';
import AuthLayout from './components/layouts/authLayout/AuthLayout';
import SignUp from './pages/signup/SignUp';
import Login from './pages/login/Login';
import SignUpVector from './assets/SignUpVector.svg';
import LoginVector from './assets/Login.svg';
import SecurityVector from './assets/Security.svg';
import ForgotVector from './assets/cuate.svg';
import Rafiki from './assets/Rafiki.svg';
import Avatar from './assets/SelectAvatar.svg';
import ForgotPassword from './pages/forgotPassword/ForgotPassword';
import ResetPassword from './pages/resetPassword/reset-password';
import UserNameAndSecurity from './pages/userNameAndSecurity/userNameAndSecurity';
import AccountVerification from './pages/accountVerification/accountVerification';
import AvatarSelection from './pages/avatarSelection/AvatarSelection';
import Dashboard from './pages/dashboard';
import PrivateRoutes from './components/routing/ProtectedRoutes';
import TopUp from './pages/topUp/TopUp';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Contacts from './pages/contacts/Contacts';
import SendMessage from './pages/sendMessage/SendMessage';
import SelectPayment from './pages/selectPayment/SelectPayment';
import TransactionHistory from './pages/transactionHistory/TransactionHistory';
import AllContacts from './pages/contacts/allContacts/AllContacts';
import AllGroups from './pages/contacts/allGroups/AllGroups';
import User from './pages/contacts/user/User';
import Classify from './pages/contacts/classify/Classify';
import Vcf from './pages/contacts/import/Vcf';
import Direct from './pages/contacts/import/Direct';
import AddGroup from './pages/contacts/addGroup/AddGroup';
import Group from './pages/contacts/allGroups/Group';
import FirstPage from './pages/firstPage/FirstPage';
import AddContactsGroup from './pages/contacts/allGroups/AddContactsGroup';
import MessageHistory from './pages/sendMessage/messageHistory/MessageHistory';
import HistoryDetails from './pages/sendMessage/messageHistory/HistoryDetails';
import MessageRules from './pages/sendMessage/messageRules/MessageRules';
import Drafts from './pages/sendMessage/drafts/Drafts';
import DraftEdit from './pages/sendMessage/drafts/DraftEdit';
import Schedule from './pages/sendMessage/scheduleMessage/Schedule';
import ScheduleMessage from './pages/sendMessage/scheduleMessage/ScheduleMessage';
import Message from './pages/sendMessage/message/Message';
import VerifyId from './pages/sendMessage/verifyId/VerifyId';
import GroupSelect from './components/message/select/GroupSelect';
import ContactSelect from './components/message/select/ContactSelect';
import Settings from './pages/settings/Settings';
import ProfileEdit from './pages/settings/ProfileEdit';
import Activity from './pages/dashboard/Activity';
import Faq from './pages/dashboard/Faq';
import Suggest from './pages/dashboard/Suggest';
import Contact from './pages/dashboard/Contact';
import PricingInfo from './pages/topUp/PricingInfo';
import SenderIdHistory from './pages/sendMessage/verifyId/SenderIdHistory';
import SelectGroup from './pages/contacts/allGroups/SelectGroup';
import PhoneVerification from './pages/accountVerification/phoneVerification';
import Archive from './pages/sendMessage/archive/Archive';
import CopyPaste from './pages/contacts/import/CopyPaste';
import PasteSelect from './components/message/select/PasteSelect';


const App = () => {
   return (
      <BrowserRouter>
         <Routes>
            <Route path="/" element={<Home />} />
            <Route
               path="/signup"
               element={<AuthLayout layoutImage={SignUpVector} />}
            >
               <Route index element={<SignUp />} />
            </Route>
            <Route
               path="/login"
               element={<AuthLayout layoutImage={LoginVector} />}
            >
               <Route index element={<Login />} />
            </Route>
            <Route
               path="/forgotpassword"
               element={<AuthLayout layoutImage={ForgotVector} />}
            >
               <Route index element={<ForgotPassword />} />
            </Route>
            <Route
               path="/reset-password"
               element={<AuthLayout layoutImage={ForgotVector} />}
            >
               <Route index element={<ResetPassword />} />
            </Route>
            <Route
               path="/username-and-security"
               element={<AuthLayout layoutImage={SecurityVector} />}
            >
               <Route index element={<UserNameAndSecurity />} />
            </Route>
            <Route
               path="/account-verification"
               element={<AuthLayout layoutImage={Rafiki} />}
            >
               <Route index element={<AccountVerification />} />
            </Route>
            <Route
               path="/settings/phone-verify"
               element={<AuthLayout layoutImage={Rafiki} />}
            >
               <Route index element={<PhoneVerification />} />
            </Route>
            <Route
               path="/select-avatar"
               element={<AuthLayout layoutImage={Avatar} />}
            >
               <Route index element={<AvatarSelection />} />
            </Route>
            <Route element={<PrivateRoutes />}>
               <Route path="/dashboard" element={<Dashboard />}>
                  <Route index element={<FirstPage />} />
                  <Route path="/dashboard/activity" element={<Activity />} />
                  <Route path="/dashboard/faq" element={<Faq />} />
                  <Route path="/dashboard/suggest" element={<Suggest />} />
                  <Route path="/dashboard/contact" element={<Contact />} />
               </Route>
            </Route>
            <Route path="/top-up" element={<Dashboard />}>
               <Route index element={<TopUp />} />
               <Route path="/top-up/payment" element={<SelectPayment />} />
               <Route
                  path="/top-up/transactions"
                  element={<TransactionHistory />}
               />
               <Route path="/top-up/pricing-info" element={<PricingInfo />} />
            </Route>
            <Route path="/contacts" element={<Dashboard />}>
               <Route index element={<Contacts />} />
               <Route path="/contacts/all-contacts" element={<AllContacts />} />
               <Route
                  path="/contacts/all-contacts-group/:id"
                  element={<AddContactsGroup />}
               />
               <Route path="/contacts/all-groups" element={<AllGroups />} />
               <Route path="/contacts/user/:id" element={<User />} />
               <Route path="/contacts/group/:id" element={<Group />} />
               <Route path="/contacts/classify" element={<Classify />} />
               <Route path="/contacts/add-vcf" element={<Vcf />} />
               <Route path="/contacts/add-direct" element={<Direct />} />
               <Route path="/contacts/add-copypaste" element={<CopyPaste />} />
               <Route path="/contacts/add-group" element={<AddGroup />} />
               <Route path="/contacts/select-group" element={<SelectGroup />} />
            </Route>
            <Route path="/send-message" element={<Dashboard />}>
               <Route index element={<SendMessage />} />
               <Route
                  path="/send-message/history"
                  element={<MessageHistory />}
               />
               <Route path="/send-message/rules" element={<MessageRules />} />
               <Route path="/send-message/drafts" element={<Drafts />} />
               <Route path="/send-message/schedule" element={<Schedule />} />
               <Route path="/send-message/message" element={<Message />} />
               <Route path="/send-message/verify" element={<VerifyId />} />
               <Route
                  path="/send-message/idHistory"
                  element={<SenderIdHistory />}
               />
               <Route
                  path="/send-message/schedule-message"
                  element={<ScheduleMessage />}
               />
               <Route
                  path="/send-message/drafts-edit/:id"
                  element={<DraftEdit />}
               />
               <Route
                  path="/send-message/history/:id"
                  element={<HistoryDetails />}
               />
               <Route
                  path="/send-message/select-group"
                  element={<GroupSelect />}
               />
               <Route
                  path="/send-message/select-contact"
                  element={<ContactSelect />}
               />
               <Route
                  path="/send-message/select-paste"
                  element={<PasteSelect />}
               />
            </Route>
            <Route path="/settings" element={<Dashboard />}>
               <Route index element={<Settings />} />
               <Route path="/settings/profile" element={<ProfileEdit />} />
               <Route path="/settings/archive" element={<Archive />} />
            </Route>

      
          
            <Route path="*" element={<Dashboard />} />
         </Routes>
      </BrowserRouter>
   );
};

export default App;

import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Container, Wrapper } from './style';
import { VscEye, VscEyeClosed } from 'react-icons/vsc';
import refreshIcon from '../../assets/refresh.svg';
import transferIcon from '../../assets/transfer.png';
import TopupIcon from '../../assets/TopupIcon.svg';
import MessageIcon from '../../assets/message2.svg';
import PhonebookIcon from '../../assets/Phonebook2.svg';
import mathFrame from '../../assets/mathFrame.svg';
import WorkHistorySharpIcon from '@mui/icons-material/WorkHistorySharp';
import RecentTable from './RecentTable';
import { useDispatch, useSelector } from 'react-redux';
import {
   getAuthSelector,
   message,
   messageSelector,
   transaction,
} from '../../components/utils/selectors/selectors';
import { Link, useNavigate } from 'react-router-dom';
import { getUserMessages } from '../../components/app/features/message/messageSlice';
import { getActivities } from '../../components/app/features/transactions/TransactionSlice';
import Slider from './Slider';
import { getUserProfile } from '../../components/app/features/auth/AuthSlice';
import { userLSAuth } from '../../components/app/features/env';
import TransferDialog from '../../components/modal/TransferDialog';

import './style.css';
import { FaAngleRight } from 'react-icons/fa';
import { getBaseUrls } from '../../components/app/features/env';

const FirstPage = () => {
   const [isShowPwd, setIsShowPwd] = React.useState(true);
   const [reset, setReset] = React.useState(false);
   const [numOfMsg, setNumOfMsg] = React.useState(0);
   const [month, setMonth] = React.useState('');
   const [balance, setBalance] = React.useState(0);
   const [dialogshow, setdialogshow] = useState(false);



   const authselector = useSelector(getAuthSelector);
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const msgSelector = useSelector(messageSelector);
   const transSelector = useSelector(transaction);
   const selector = useSelector(messageSelector);
   const [dialogOpen, setDialogOpen] = useState(false);

   const togglePassword = () => setIsShowPwd(!isShowPwd);
   const handleOpen = () => setDialogOpen(true);
   const handleClose = () => setDialogOpen(false);

   const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
   ];
 



   React.useEffect(() => {
      dispatch(getUserProfile());
   
      setTimeout(() => {
         if (authselector.updated === true) {
       
            setReset(false);
         }
      }, 1500);
   }, [reset === true]);

   React.useEffect(() => {
      dispatch(getUserMessages());
      dispatch(getActivities({ page: 1 }));
   }, [dispatch]);

   React.useEffect(() => {
      if (authselector.updatedProfile !== null) {
         setBalance(
            authselector.updatedProfile.balance !== null
               ? authselector.updatedProfile.balance
               : 0
               
         );
         
      }
   }, [authselector.updated === true]);

   React.useEffect(() => {
      const fetchData = async () => {
         try {
            const token = localStorage.getItem('accessToken');
            const url = `${getBaseUrls().data}/v1/message/${
               userLSAuth().id
            }?page=1&take=10+&state=history`;
            const response = await fetch(url, {
               method: 'GET',
               headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
               },
            });

            if (response.ok) {
               const result = await response.json();
               const messages = result.data;
               let currentDate = new Date();
               let currentMonth = currentDate.getMonth();
               let currentYear = currentDate.getFullYear();
               setMonth(`${monthNames[currentMonth]} ${currentYear}`);
               if (messages.length > 0) {
                  let count = 0;
                  const month = new Date().getMonth();
                  const year = new Date().getFullYear();
                  const isoMonthString = new Date(year, month, 1).toISOString();
                  messages.filter((item) => {
                     const itemDate = new Date(item.createdAt);
                     const currentMonthCompare = new Date(isoMonthString);
                     const isSameMonth =
                        currentMonthCompare.getFullYear() ===
                           itemDate.getFullYear() &&
                        currentMonthCompare.getMonth() === itemDate.getMonth();

                     if (isSameMonth) {
                        count += 1;
                     }
                  });
                  setNumOfMsg(count);
               }
            }
         } catch (error) {
            console.error('Error:', error);
         }
      };

      fetchData();
   }, []);
   return (
      <Wrapper>
         <Container>
            <Box
               sx={{
                  display: 'flex',
                  columnGap: '1%',
                  flexWrap: 'wrap',
                  rowGap: '1vh',
               }}
            >
               <Box
                  sx={{
                     position: 'relative',
                     width: '49.5%',
                     borderTopLeftRadius: '16px',
                     boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.2)',
                     background:
                        'linear-gradient(90deg, #374DA0 0%, rgba(55, 77, 160, 0.7) 71.67%)',
                     padding: '10px 20px',
                     '@media(max-width: 705px)': {
                        borderTopRightRadius: '16px',
                        width: '100%',
                     },
                  }}
               >
                  <Typography
                     sx={{
                        color: '#fff',
                        fontSize: 'clamp(1.3rem, 3vw, 1.5rem)',
                        fontFamily: "'Raleway', 'Montserrat', sans-serif",
                        fontWeight: '400',
                        textAlign: 'center',
                        marginBottom: '1rem',
                     }}
                  >
                     Account Balance
                  </Typography>
                  <Typography
                     sx={{
                        color: '#fff',
                        fontSize: 'clamp(1.5rem, 2.5vw, 1.7rem)',
                        fontFamily: "'Raleway', 'Montserrat', sans-serif",
                        fontWeight: '500',
                        textAlign: 'center',
                     }}
                  >
                     {isShowPwd ? userLSAuth()?.balance ?? '0' : '*****'}
                  </Typography>
                  <Typography
                     sx={{
                        color: '#fff',
                        fontSize: 'clamp(1.2rem, 2.5vw, 1.4rem)',
                        fontFamily: "'Raleway', 'Montserrat', sans-serif",
                        fontWeight: '400',
                        textAlign: 'center',
                        marginBottom: '3rem',
                     }}
                  >
                     SMS Units
                  </Typography>
                  <Button
                     sx={{
                        color: '#374DA0',
                        display: 'flex',
                        alignItems: 'center',
                        background: '#fff',
                        width: 'max-content',
                        padding: '5px 10px ',
                        borderRadius: '25px',
                        fontFamily: "'Raleway', 'Montserrat', sans-serif",
                        fontSize: '14px',
                        position: 'absolute',
                        bottom: '15px',
                        left: '20px',
                        cursor: 'pointer',
                        ':hover': {
                           background: '#fff',
                        },
                     }}
                     onClick={() => setReset(true)}
                  >
                     Refresh{' '}
                     <img
                        src={refreshIcon}
                        className={reset === true ? 'rotating-element' : ''}
                        style={{
                           marginLeft: '5px',
                           width: '20px',
                        }}
                        alt="refresh"
                     />
                  </Button>
                  <Button
        onClick={handleOpen}
        sx={{
          color: '#374DA0',
          display: 'flex',
          alignItems: 'center',
          background: '#fff',
          width: 'max-content',
          padding: '5px 10px',
          borderRadius: '25px',
          fontFamily: "'Raleway', 'Montserrat', sans-serif",
          fontSize: '14px',
          position: 'absolute',
          bottom: '15px',
          left: {
            xs: '33%',
            sm: '26%', 
          },
          cursor: 'pointer',
          ':hover': {
            background: '#fff',
          },
        }}
      >
        Transfer units
        <img
          src={transferIcon}
      
          style={{
            marginLeft: '5px',
            width: '20px',
          }}
          alt="refresh"
        />
      </Button>
      <TransferDialog open={dialogOpen} onClose={handleClose} setReset={setReset} />
                  {isShowPwd ? (
                     <VscEye
                        size={25}
                        style={{
                           position: 'absolute',
                           bottom: '15px',
                           right: '20px',
                           color: '#fff',
                           cursor: 'pointer',
                        }}
                        onClick={togglePassword}
                     />
                  ) : (
                     <VscEyeClosed
                        size={25}
                        style={{
                           position: 'absolute',
                           bottom: '15px',
                           right: '20px',
                           color: '#fff',
                           cursor: 'pointer',
                        }}
                        onClick={togglePassword}
                     />
                  )}
               </Box>
               <Box
                  sx={{
                     width: '49.5%',
                     background: '#FFFFFF',
                     borderTopRightRadius: '16px',
                     boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.2)',
                     padding: '10px 20px',
                     cursor: 'pointer',
                     '&:hover': {
                        transform: 'scale(1.02)',
                     },
                     '@media(max-width: 705px)': {
                        width: '100%',
                        borderTopRightRadius: '0',
                     },
                  }}
                  onClick={() => navigate('/send-message/history')}
               >
                  <Typography
                     sx={{
                        textAlign: 'center',
                        fontFamily: "'Raleway', 'Montserrat', sans-serif",
                        fontSize: 'clamp(0.95rem, 1.5vw, 1rem)',
                        fontWeight: '600',
                     }}
                  >
                     Message History
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                     <Box
                        sx={{
                           display: 'flex',
                           flexDirection: 'column',
                           alignItems: 'center',
                        }}
                     >
                        <img
                           src={mathFrame}
                           alt="card"
                           style={{ width: '80px', margin: '1rem 0 0.5rem 0' }}
                        />
                        <Typography
                           sx={{
                              textAlign: 'center',
                              fontFamily: "'Raleway', 'Montserrat', sans-serif",
                              fontSize: 'clamp(0.95rem, 1.5vw, 1rem)',
                              fontWeight: '700',
                              color: '#374DA0',
                           }}
                        >
                           Check Message Status
                        </Typography>
                     </Box>
                     <Box
                        sx={{
                           display: 'flex',
                           flexDirection: 'column',
                           alignItems: 'center',
                        }}
                     >
                        <Box
                           sx={{
                              width: '80px',
                              margin: '1rem 0 0.5rem 0',
                              height: '80px',
                              borderRadius: '50%',
                              background: 'rgba(55, 77, 160, 0.2)',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                           }}
                        >
                           <Typography
                              sx={{
                                 fontWeight: '700',
                                 fontSize: '1.5rem',
                                 fontFamily:
                                    "'Raleway', 'Montserrat', sans-serif",
                                 color: '#374DA0',
                                 margin: '0',
                                 lineHeight: '1',
                              }}
                           >
                              {msgSelector?.messages ? numOfMsg : 0}
                           </Typography>
                           <Typography
                              sx={{
                                 // marginTop: '0.4rem',
                                 fontWeight: '600',
                                 fontSize: '0.85rem',
                                 fontFamily:
                                    "'Raleway', 'Montserrat', sans-serif",
                                 color: '#374DA0',
                                 lineHeight: '1.6',
                              }}
                           >
                              Messages
                           </Typography>
                        </Box>

                        <Typography
                           sx={{
                              textAlign: 'center',
                              fontFamily: "'Raleway', 'Montserrat', sans-serif",
                              fontSize: 'clamp(0.95rem, 1.5vw, 1rem)',
                              fontWeight: '700',
                              color: '#374DA0',
                           }}
                        >
                           {month}
                        </Typography>
                     </Box>
                  </Box>
               </Box>
            </Box>
            <Box
               sx={{
                  justifyContent: 'space-between',
                  display: 'flex',
                  columnGap: '1%',
                  marginTop: '1vh',
                  flexWrap: 'wrap',
                  rowGap: '1vh',
               }}
            >
               <Box
                  sx={{
                     width: '24.25%',
                     boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.2)',
                     background: '#FFFFFF',
                     display: 'flex',
                     flexDirection: 'column	',
                     alignItems: 'center',
                     justifyContent: 'center',
                     padding: '15px 0',
                     cursor: 'pointer',
                     borderBottomLeftRadius: '16px',
                     '&:hover': {
                        transform: 'scale(1.02)',
                     },
                     '@media(max-width: 705px)': {
                        width: '49.5%',
                        borderBottomLeftRadius: '0',
                     },
                  }}
                  onClick={() => navigate('/send-message/message')}
               >
                  <Box
                     borderRadius={50}
                     color="#374DA0"
                     display="flex"
                     textAlign="center"
                     alignItems="center"
                     justifyContent={'center'}
                     width={38}
                     height={38}
                     bgcolor="rgba(55, 77, 160, 0.2)"
                  >
                     <img
                        src={MessageIcon}
                        alt="messageIcon"
                        style={{
                           width: '30px',
                        }}
                     />
                  </Box>

                  <Typography
                     sx={{
                        fontSize: 'clamp(0.95rem, 1.5vw, 1rem)',
                        fontWeight: '600',
                        marginTop: '0.5rem',
                     }}
                  >
                     Send Message
                  </Typography>
               </Box>
               <Box
                  sx={{
                     width: '24.25%',
                     padding: '15px 0',
                     boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.2)',
                     background: '#FFFFFF',
                     display: 'flex',
                     flexDirection: 'column	',
                     alignItems: 'center',
                     justifyContent: 'center',
                     cursor: 'pointer',
                     '&:hover': {
                        transform: 'scale(1.02)',
                     },
                     '@media(max-width: 705px)': {
                        width: '49.5%',
                     },
                  }}
                  onClick={() => navigate('/top-up')}
               >
                  <img
                     src={TopupIcon}
                     alt="TopupIcon"
                     style={{
                        width: '40px',
                     }}
                  />
                  <Typography
                     sx={{
                        fontFamily: "'Raleway', 'Montserrat', sans-serif",
                        fontSize: 'clamp(0.95rem, 1.5vw, 1rem)',
                        fontWeight: '600',
                        marginTop: '0.5rem',
                     }}
                  >
                     Top-Up
                  </Typography>
               </Box>

               <Box
                  sx={{
                     width: '24.25%',
                     boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.2)',
                     background: '#FFFFFF',
                     display: 'flex',
                     flexDirection: 'column	',
                     alignItems: 'center',
                     justifyContent: 'center',
                     padding: '15px 0',
                     cursor: 'pointer',
                     '&:hover': {
                        transform: 'scale(1.02)',
                     },
                     '@media(max-width: 705px)': {
                        width: '49.5%',
                        borderBottomLeftRadius: '16px',
                     },
                  }}
                  onClick={() => navigate('/contacts')}
               >
                  <Box
                     borderRadius={50}
                     color="#374DA0"
                     display="flex"
                     textAlign="center"
                     alignItems="center"
                     justifyContent={'center'}
                     width={38}
                     height={38}
                     bgcolor="rgba(55, 77, 160, 0.2)"
                  >
                     <img
                        src={PhonebookIcon}
                        alt="contactIcon"
                        style={{
                           width: '20px',
                        }}
                     />
                  </Box>

                  <Typography
                     sx={{
                        fontFamily: "'Raleway', 'Montserrat', sans-serif",
                        fontSize: 'clamp(0.95rem, 1.5vw, 1rem)',
                        fontWeight: '600',
                        marginTop: '0.5rem',
                     }}
                  >
                     Contacts
                  </Typography>
               </Box>
               <Box
                  sx={{
                     width: '24.25%',
                     borderBottomRightRadius: '16px',
                     boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.2)',
                     background: '#FFFFFF',
                     display: 'flex',
                     flexDirection: 'column	',
                     alignItems: 'center',
                     justifyContent: 'center',
                     padding: '15px 0',
                     cursor: 'pointer',
                     '&:hover': {
                        transform: 'scale(1.02)',
                     },
                     '@media(max-width: 705px)': {
                        width: '49.5%',
                     },
                  }}
                  onClick={() => navigate('/top-up/transactions')}
               >
                  <Box
                     borderRadius={50}
                     color="#374DA0"
                     display="flex"
                     textAlign="center"
                     alignItems="center"
                     justifyContent={'center'}
                     width={38}
                     height={38}
                     bgcolor="rgba(55, 77, 160, 0.2)"
                  >
                     <WorkHistorySharpIcon
                        style={{
                           width: '40px',
                        }}
                     />
                  </Box>

                  <Typography
                     sx={{
                        fontFamily: "'Raleway', 'Montserrat', sans-serif",
                        fontSize: 'clamp(0.95rem, 1.5vw, 1rem)',
                        fontWeight: '600',
                        marginTop: '0.5rem',
                     }}
                  >
                     Transactions
                  </Typography>
               </Box>
            </Box>
            <Box
               sx={{
                  my: '2rem',
                  // border: '1px solid red',
                  width: '90%',
                  marginX: 'auto',
               }}
            >
               <Typography
                  sx={{
                     fontFamily: "'Raleway', 'Montserrat', sans-serif",
                     fontWeight: '600',
                     fontSize: 'clamp(0.95rem, 1.5vw, 1rem)',
                     mb: 2,
                  }}
               >
                  New Information
               </Typography>
               <Slider handleOpen={handleOpen} />
            </Box>
            <Box
               sx={{
                  margin: '4rem 0 1rem 0',
                  display: 'flex',
                  justifyContent: 'space-between',
               }}
            >
               <Typography
                  sx={{
                     fontWeight: '600',
                     fontSize: 'clamp(0.95rem, 1.5vw, 1rem)',
                  }}
               >
                  Recent Activities
               </Typography>
               <Typography
                  sx={{
                     fontWeight: '600',
                     fontSize: 'clamp(0.95rem, 1.5vw, 1rem)',
                     color: '#374da0',
                  }}
               >
                  <Link
                     to="/dashboard/activity"
                     style={{
                        color: '#374da0',
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: '5px',
                     }}
                  >
                     See All
                     <FaAngleRight />
                  </Link>
               </Typography>
            </Box>

            <Box>
               {transSelector?.activities.length > 0 ? (
                  <RecentTable />
               ) : (
                  <Typography
                     sx={{
                        fontSize: '1rem',
                        fontFamily: "'Raleway', 'Montserrat', sans-serif",
                     }}
                  >
                     {`	Hey ${
                        userLSAuth()?.fullName
                     }, your latest Activities will appear here!`}
                  </Typography>
               )}
            </Box>
         </Container>
      </Wrapper>
   );
};

export default FirstPage;

import React, { useEffect, useState } from 'react';
import { Container, Typography, Button, Grid, TextField, Slider, Switch, Box, Paper } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { smsCost, topupWithNaira, topupWithUnit } from '../app/features/message/messageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { messageSelector } from '../utils/selectors/selectors';
import { LoadingButton } from '@mui/lab';
import { useMediaQuery } from "@mui/material";
import Equal from '../../assets/equal.svg'



const CalculatorApp = () => {
     const dispatch = useDispatch();
  const [isSmsCostCalculator, setIsSmsCostCalculator] = useState(true);
  const [isAmountMode, setIsAmountMode] = useState(true);
  const [unitCost, setUnitCost] = useState('');
  const [cost, setCost] = useState('');
  const [totalUnits, setTotalUnits] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const selector = useSelector(messageSelector);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [priceLoading, setPriceLoading] = useState(false);
  const [smsLoading, setSmsLoading] = useState(false);

  

useEffect(() => {

  setCost('')
  priceCalculatorFormik.values.unit = ''
}, [isAmountMode])


  const smsCostFormik = useFormik({
    initialValues: {
        dndContacts : 0,
        nonDndContacts : 0,
        messagePage : 0,
    },
    validationSchema: Yup.object({
        dndContacts: Yup.number().min(0, 'Must be greater than or equal to 0').required('Required'),
        nonDndContacts: Yup.number().min(0, 'Must be greater than or equal to 0').required('Required'),
        messagePage: Yup.number().min(1).max(10).required('Required'),
    }),
    onSubmit: (values) => {
        const payload = {
            dndContacts: values.dndContacts,
            nonDndContacts: values.nonDndContacts,
            messagePage : values.messagePage,
         };
      

         setSmsLoading(true)
      dispatch(smsCost(payload))
  
         .then((response) => {
   
            setTotalAmount(response.payload.totalCost.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2, 
            }))
            setTotalUnits(response.payload.totalUnit.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2, 
            }))
            setSmsLoading(false)
         })
         .catch((error) => {
            console.error('Error:', error);
            setSmsLoading(false)
         });
    },
  });

  const priceCalculatorFormik = useFormik({
    initialValues: {
      unit: 2000,
     

    },
    validationSchema: Yup.object({
      unit: Yup.number().min(0, 'Must be greater than or equal to 0').required('Required'),
     
    }),
    onSubmit: (values) => {

        if (isAmountMode){
             const payload = {
            amount: values.unit,
         };
         setPriceLoading(true)
         dispatch(topupWithNaira(payload.amount))
         
  
         .then((response) => {
            console.log('API response:', response.payload);
            setCost(response.payload .toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }));

           
             setPriceLoading(false)
         })
         .catch((error) => {
            console.error('Error:', error);
            setPriceLoading(false)
         });
        }else{
            const payload = {
                unit: values.unit,
             };
             console.log(payload);
             dispatch(topupWithUnit(payload.unit))
  
             .then((response) => {
                console.log('API response:', response.payload);
                setCost(response.payload .toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }));
                // resetForm();
             })
             .catch((error) => {
                console.error('Error:', error);
             });
        }
      
    },
  });

  return (
    <Container maxWidth="md" disableGutters  >
       
      <Grid container disableGutters spacing={1}>
        {/* Left Panel */}
        <Grid  item xs={12} sm={4}>
        <Paper 
  elevation={2} 
  style={{ 
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center',          
    padding: '20px', 
    height: '23rem', 
    background: '#374DA0', 
    borderRadius: '0',
    position: 'relative'          
  }}
  
>

  <Typography
    sx={{
      color: 'white',
      fontWeight: '700',
      fontSize: '1.3rem',
      position: 'absolute',        
      top: '20px',                 
      textAlign: 'center'
    }}
  >
    Select Your Calculator
  </Typography>

 
  <div 
    style={{
      display: 'flex', 
      flexDirection: 'column', 
      gap: '3rem',   
      justifyContent: 'center',   
      alignItems: 'center',       
      flex: 1,                    
      width: '100%'
    }}
  >

    <Button
      fullWidth
      variant={!isSmsCostCalculator ? 'contained' : 'outlined'}
      style={{
        maxWidth: '300px', 
        background: !isSmsCostCalculator ? 'white' : '#D9D9D9',
        color: !isSmsCostCalculator ? '#374DA0' : '#0F0E0E',
        opacity: !isSmsCostCalculator ? '100%' : '40%',
        borderRadius: '0',
        fontWeight: '700',
        paddingTop:"0.8rem",
        paddingBottom:"0.8rem"
      }}
      onClick={() => setIsSmsCostCalculator(false)}
    >
      Price Calculator
    </Button>

    {/* SMS Cost Calculator Button */}
    <Button
      fullWidth
      variant={isSmsCostCalculator ? 'contained' : 'outlined'}
      style={{
        maxWidth: '300px',
        background: isSmsCostCalculator ? 'white' : '#D9D9D9',
        color: isSmsCostCalculator ? '#374DA0' : '#0F0E0E',
        opacity: isSmsCostCalculator ? '100%' : '40%',
        borderRadius: '0',
        fontWeight: '700',
        paddingTop:"0.8rem",
        paddingBottom:"0.8rem"
      }}
      onClick={() => setIsSmsCostCalculator(true)}
    >
      SMS Cost Calculator
    </Button>
  </div>
</Paper>

    


        </Grid>

        {/* Right Panel  */}
        <Grid disableGutters item xs={12} sm={8}>
          <Paper square elevation={0}  disableGutters style={{ padding: '20px', height: '100%' }}>
            {isSmsCostCalculator ? (
              <form onSubmit={smsCostFormik.handleSubmit}>
                <div   style={{
        display: isMobile ? "block" : "flex",
        justifyContent: isMobile ? "unset" : "space-between",
      }}>
                   <div style={{flex: 1}}>
                   <span style={{ color: "#363636", fontWeight: "500",fontSize:"0.9rem" }}>
                   Number of DND Contacts
                  </span>
                   <TextField
                  fullWidth
                  type="number"
                  name="dndContacts"
                  label=""
                  value={smsCostFormik.values.dndContacts }
                  onChange={smsCostFormik.handleChange}
                  error={smsCostFormik.touched.dndContacts  && Boolean(smsCostFormik.errors.dndContacts )}
                  helperText={smsCostFormik.touched.dndContacts  && smsCostFormik.errors.dndContacts }
                 
                  sx={{
                    marginBottom: "10px",
                    marginTop:"5px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { borderColor: "#D0D0D0" },
                      "&:hover fieldset": { borderColor: "#D0D0D0" },
                      "&.Mui-focused fieldset": { borderColor: "#D0D0D0" },

                    },
                  }}
                />
                    <span style={{ color: "#363636", fontWeight: "500",fontSize:"0.9rem"}}>
                    Number of Non-DND Contacts
                  </span>
                <TextField
                  fullWidth
                  type="number"
                  name="nonDndContacts"
                  label=""
                  value={smsCostFormik.values.nonDndContacts }
                  onChange={smsCostFormik.handleChange}
                  error={smsCostFormik.touched.nonDndContacts  && Boolean(smsCostFormik.errors.nonDndContacts )}
                  helperText={smsCostFormik.touched.nonDndContacts  && smsCostFormik.errors.nonDndContacts }
                 
                  sx={{
                    marginTop: "5px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { borderColor: "#D0D0D0" },
                      "&:hover fieldset": { borderColor: "#D0D0D0" },
                      "&.Mui-focused fieldset": { borderColor: "#D0D0D0" },
                    },
                  }}
                />

                <Box style={{ marginTop: '20px', color: "#363636" }}>
                  <Typography gutterBottom sx={{fontWeight:"500"}}>Message Length</Typography>
                  <Slider
                    name="messagePage "
                    value={smsCostFormik.values.messagePage }
                    onChange={(e, value) => smsCostFormik.setFieldValue('messagePage', value)}
                    step={1}
                    marks
                    min={1}
                    max={10}
                  />
                  <Typography sx={{fontWeight:"500", fontSize:'0.8rem'}}>Pages: {smsCostFormik.values.messagePage }</Typography>
                  <Typography color="error" variant="body2">
                    {smsCostFormik.touched.messagePage  && smsCostFormik.errors.messagePage }
                  </Typography>
                </Box>

                <LoadingButton
  fullWidth
  variant="contained"
  loading={ smsLoading}
 

  style={{ marginTop: '20px' }}
  type="submit"
>
  Calculate
</LoadingButton>
                   </div>
                   <div style={{ display: isMobile ? "none" : "flex", alignItems:"center",margin:"0 1rem",justifyContent:"center"}}>
                   <img
                  src={Equal}
                  alt=""
                  style={{ width: "30px", height: "40px", justifySelf:"center" }}
                />
                   </div>
                   <div style={{flex: 1}}>
                   <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
         
                  }}
                >
                  <div
                    style={{
                      background: "#374DA033",
                      color: "#374DA0",
                  
                    
                      width: "100%",
                      minHeight: "7rem",
                      display: "flex",
                      flexDirection: "column",
                    
                      marginTop: "2rem",
                      borderBottom: "1px solid #25336B",
                      borderTopLeftRadius: "8px", 
                      borderTopRightRadius: "8px",
                      
                    
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        padding: "16px",
                      }}
                    >
                      <div>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: { xs: "0.9rem", sm: "1rem" },
                            textAlign: "left",
                            display:"flex",
                            top:"0"
                          }}
                        >
                         Total Units
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {totalUnits && (
                          <>
                            <Typography
                              sx={{
                                fontSize: { xs: "1.5rem", sm: "2rem" },
                                fontWeight: 700,
                              }}
                            >
                              
                              {totalUnits}
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: { xs: "0.9rem", sm: "1rem" },
                                textAlign: "end",
                              }}
                            >
                            SMS units
                            </Typography>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      background: "#374DA033",
                      color: "#374DA0",
                      
                    
                      width: "100%",
                      minHeight: "7rem",
                      display: "flex",
                      flexDirection: "column",
                    
                     borderTopLeftRadius:"0",
                     borderTopLeftRight:"0",
                     borderBottomLeftRadius: "8px", 
                     borderBottomRightRadius: "8px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        padding: "16px",
                      }}
                    >
                      <div>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: { xs: "0.9rem", sm: "1rem" },
                            textAlign: "left",
                            display:"flex",
                            top:"0"
                          }}
                        >
                        Total Cost in Naira
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {totalAmount && (
                          <>
                            <Typography
                              sx={{
                                fontSize: { xs: "1.5rem", sm: "2rem" },
                                fontWeight: 700,
                              }}
                            >
                              {totalAmount}
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: { xs: "0.9rem", sm: "1rem" },
                                textAlign: "end",
                              }}
                            >
                              ₦
                            </Typography>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
            
                
                </div>
                   </div>
                </div>

               

              </form>
            ) : (
              <form onSubmit={priceCalculatorFormik.handleSubmit}>
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: { xs: "flex-start", sm: "center" },
                  gap: "1rem",
                }}
              >
                <div
                  style={{
                    fontWeight: "600",
                    fontSize: "1.3rem",
                    color: "#373636",
                    maxWidth: "100%",
                  }}
                >
                  {isAmountMode
                    ? "Enter the amount below in naira (₦)"
                    : "Enter your SMS units below"}
                </div>
                <div>
                  <span style={{ color: "#363636", fontWeight: "600", fontSize: "0.9rem" }}>
                    SMS Units
                  </span>
                  <Switch
                    checked={isAmountMode}
                    onChange={() => setIsAmountMode(!isAmountMode)}
                  />
                  <span style={{ color: "#363636", fontWeight: "600", fontSize: "0.9rem" }}>
                    Amount
                  </span>
                </div>
              </Typography>
            
              <Box
                style={{

                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "2rem",
                  flexDirection: { xs: "column", md: "row" },
                }}
                sx={{
                  display:{xs:"block",sm:"flex"},
                }}
              >
                {/* Left Section */}
                <div style={{ flex: 1, width: "100%" }}>
                  <span style={{ color: "#363636", fontWeight: "500" }}>
                    {isAmountMode ? "Amount (₦)" : "Number of SMS Units"}
                  </span>
                  <TextField
                    fullWidth
                    type="number"
                    name="unit"
                    value={priceCalculatorFormik.values.unit}
                    onChange={priceCalculatorFormik.handleChange}
                    sx={{
                      marginTop: "10px",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { borderColor: "#D0D0D0" },
                        "&:hover fieldset": { borderColor: "#D0D0D0" },
                        "&.Mui-focused fieldset": { borderColor: "#D0D0D0" },
                      },
                      marginTop: { xs: "10px", sm: "4rem" },
                    }}
                  />
                   <LoadingButton
                    fullWidth
                    variant="contained"
                    loading={ priceLoading}
                   

                    sx={{
                      marginTop: "20px",
                      fontSize: { xs: "0.8rem", sm: "1rem" },
                      padding: { xs: "6px 8px", sm: "12px 14px" },
                      borderRadius:"4px"
                    }}
                    type="submit"
                  >
                    Calculate
                  </LoadingButton>
                </div>
                
                <Box  sx={{
    width: "30px",
    height: "40px",
    display: {
      xs: "none",
      sm: "block", 
    },
  }} >
                <img
  src={Equal}
  alt=""
  style={{ alignSelf: "center" }}
 
/>
                </Box>
             
                {/* Right Section */}
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      background: "#374DA033",
                      color: "#374DA0",
                      borderRadius: "8px",
                    
                      width: "100%",
                      minHeight: "7rem",
                      display: "flex",
                      flexDirection: "column",
                    
                      marginTop: "2rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        padding: "16px",
                      }}
                    >


                       
                      <div>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: { xs: "0.9rem", sm: "1rem" },
                            textAlign: "left",
                            display:"flex",
                            top:"0"
                          }}
                        >
                          {isAmountMode ? "You will get..." : "You will pay..."}
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {cost && (
                          <>
                            <Typography
                              sx={{
                                fontSize: { xs: "1.5rem", sm: "2rem" },
                                fontWeight: 700,
                              }}
                            >
                              {cost}
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: { xs: "0.9rem", sm: "1rem" },
                                textAlign: "end",
                              }}
                            >
                              {isAmountMode ? "SMS units" : "₦"}
                            </Typography>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
            
                 
                </div>
              </Box>
            </form>
            
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CalculatorApp;

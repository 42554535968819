import React from 'react';
import {
   Box,
   Typography,
   FormControl,
   Card,
   Dialog,
   MenuItem,
   Menu,
   Checkbox,
   CircularProgress,
} from '@mui/material';
import { FaTimes, FaDownload } from 'react-icons/fa';
import { Container, TextInput, FlatButton, AddImage } from './style';
import backImg from '../../../assets/back.svg';
import classifyImg from '../../../assets/classify.svg';
import infoImg from '../../../assets/info.svg';
import addImg from '../../../assets/FAB.svg';
import trash from '../../../assets/trash.svg';
import msgIcon from '../../../assets/messageIcon3.svg';
import verticalMenu from '../../../assets/verticalMenu.svg';
import { FaSearch } from 'react-icons/fa';
import NoContacts from '../../../components/contacts/NoContact';
import { useNavigate } from 'react-router-dom';
import ContactPricingInfo from './ContactPricingInfo';
import AddContact from './AddContact';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { contactSelector } from '../../../components/utils/selectors/selectors';
import {
   deleteMultipleUsers,
   fetchContacts,
   downloadContacts,
} from '../../../components/app/features/contacts/contactSlice';
import { addSelectedContacts } from '../../../components/app/features/message/messageSlice';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getBaseUrls, userLSAuth } from '../../../components/app/features/env';

const DropDown = styled(Menu)(
   ({ theme }) => `
	position: absolute;
	right: 0;

& .MuiPopover-paper {
	border-radius:0;
}
& .MuiList-root, & .MuiMenu-list {
	padding:0;
	border-radius:0;
}

	`
);
const Activity = styled(MenuItem)(
   ({ theme }) => `
	min-height:unset;
	padding:5px 0 5px 15px;
 box-sizing: border-box;
 white-space: nowrap;
	width: 160px;
	font-weight:700;
	  font-family: 'Raleway', 'Montserrat', sans-serif;
	border-radius:0;
	

	`
);

const AllContacts = () => {
   const navigate = useNavigate();
   const selector = useSelector(contactSelector);
   const dispatch = useDispatch();
   const [sorted, setSorted] = React.useState([]);
   const [isOpen, setIsOpen] = React.useState(false);
   const [add, setAdd] = React.useState(false);
   const [checkedList, setCheckedList] = React.useState([]);
   const [itemsChecked, setItemsChecked] = React.useState(false);
   const [action, setAction] = React.useState('');
   const [anchorEl, setAnchorEl] = React.useState(null);
   const [isDelete, setIsDelete] = React.useState(false);
   const [deleted, setDeleted] = React.useState(false);
   const [search, setSearch] = React.useState('');
   const [page, setPage] = React.useState(1);
   const [take, setTake] = React.useState(50);

   const handleClose = () => setIsOpen(false);

   const setDialogState = (value) => {
      setAdd(value);
   };
   const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

   const handleCheckboxClick = (e) => {
      const { value, checked } = e.target;

      if (checked) {
         setCheckedList([...checkedList, value * 1]);
      } else {
         setItemsChecked(false);
         setCheckedList(checkedList.filter((item) => item != value));
      }
   };

   const selectItem = (e) => {
      const { checked } = e.target;
      const collection = [];

      if (checked) {
         sorted.map((item, id) => collection.push(item.id));
      }

      setCheckedList(collection);
      setItemsChecked(checked);
   };

   const setClose = () => setAnchorEl(false);
   const handleChange = (value) => {
      setAction(value);
      setAnchorEl(null);
   };

   const actions = ['Send Message', 'Delete'];

   const deleteUsers = () => {
      dispatch(
         deleteMultipleUsers({
            userId: selector?.admin?.id,
            users: checkedList,
            itemsChecked
         })
      );
      
      setTimeout(() => {
         setDeleted(true);
         setIsDelete(false);
         setAction('');
      }, 500);
   };
   const loadmore = () => {
      if (selector.hasMore && selector.loading !== 'pending') {
       
         setTake(take + 50);
         
      }
   };

   const loading = useSelector((state) => selector.loading || 'idle');

   const handleExport = async () => {
      const result = await dispatch(downloadContacts());

      if (result.meta.requestStatus === 'fulfilled') {
         // Perform the fetch to download the Blob after ensuring the downloadContacts action was successful
         const token = localStorage.getItem('accessToken');
         const response = await fetch(
            `${getBaseUrls().data}/v1/contact-download/${
               userLSAuth().id
            }?token=${token}`,
            {
               headers: {
                  Authorization: `Bearer ${token}`,
               },
               method: 'GET',
            }
         );

         if (response.ok) {
            const blob = await response.blob();

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'contacts.csv';
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
         } else {
            console.error('Failed to download contacts:');
         }
      } else {
         console.error('Download action failed:', result.error.message);
      }
   };

   // get contacts
   React.useEffect(() => {
      dispatch(
         fetchContacts({
            page,
            take
         })
      );
   }, [take]); 

   // search functionality
   const handleSearch = (event) => {
      setSearch(event.target.value);
   };

   React.useEffect(() => {
      if (sorted.length === 0 || selector.contacts.length > 0) {
         setSorted(
            [...selector.contacts].sort(function (a, b) {
               if (a.fullName.toLowerCase() < b.fullName.toLowerCase()) {
                  return -1;
               }
               if (a.fullName.toLowerCase() > b.fullName.toLowerCase()) {
                  return 1;
               }
               return 0;
            })
         );
         if (search != '') {
            setSorted(
               sorted.filter((item) => {
                  if (
                     item.fullName
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                     item.number.includes(search)
                  ) {
                     return [item];
                  }
               })
            );
         }
      }
   }, [selector.contacts, search]);

   const handleSendMessage = () => {
      const arr = [];
      if (checkedList.length > 0) {
         [...selector.contacts].map((item, ind) => {
            if (checkedList.includes(item.id)) {
               arr.push(item);
            }
         });
      }
      dispatch(addSelectedContacts(arr));
      navigate('/send-message/message');
   };

   return (
      <Container>
         {sorted.length > 0 ? (
            <Card
               sx={{
                  width: '90%',
                  maxWidth: '615px',
                  minHeight: '80vh',
                  margin: '2rem auto',
                  position: 'relative',
                  boxShadow:
                     '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
                  padding: '20px ',
                  background: '#EFEEF4',
                  borderTopLeftRadius: '25px',
                  borderTopRightRadius: '25px',
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
               }}
            >
               {action === '' && (
                  <AddImage
                     src={addImg}
                     alt="add contacts"
                     onClick={() => setAdd(true)}
                  />
               )}
               <Box
                  sx={{
                     margin: '0 auto',
                     marginTop: '1rem',
                     border: '1px solid transparent',
                     position: 'relative',
                  }}
               >
                  <Dialog onClose={handleClose} open={isOpen}>
                     <ContactPricingInfo />
                  </Dialog>
                  <Dialog
                     onClose={() => {
                        setIsDelete(false);
                        setAction('');
                     }}
                     open={isDelete}
                  >
                     <Card sx={{ padding: '20px 30px ' }}>
                        <Typography
                           sx={{
                              fontWeight: '600',
                              fontSize: '(1rem, 1.5vw, 1.1rem)',
                              textAlign: 'center',
                              marginBottom: '2rem',
                           }}
                        >
                           Are you sure you want to delete the selected
                           contacts?
                        </Typography>
                        <Box
                           sx={{
                              display: 'flex',
                              justifyContent: 'center',
                           }}
                        >
                           <Typography
                              sx={{
                                 fontWeight: '600',
                                 fontSize: '(1rem, 1.5vw, 1.1rem)',
                                 cursor: 'pointer',
                                 marginRight: ' 1rem',
                              }}
                              onClick={() => {
                                 setIsDelete(false);
                                 setAction('');
                              }}
                           >
                              Cancel
                           </Typography>
                           <Typography
                              sx={{
                                 fontWeight: '600',
                                 fontSize: '(1rem, 1.5vw, 1.1rem)',
                                 color: 'red',
                                 cursor: 'pointer',
                              }}
                              onClick={() => {
                                 deleteUsers();
                              }}
                           >
                              Yes
                           </Typography>
                        </Box>
                     </Card>
                  </Dialog>
                  <img
                     src={backImg}
                     alt="back"
                     style={{
                        position: 'absolute',
                        width: '40px',
                        cursor: 'pointer',
                     }}
                     onClick={() => window.history.back()}
                  />

                  <DropDown
                     id="basic-menu"
                     anchorEl={anchorEl}
                     open={Boolean(anchorEl)}
                     onClose={setClose}
                     MenuListProps={{
                        'aria-labelledby': 'basic-button',
                     }}
                  >
                     {actions.map((action, index) => (
                        <Activity
                           key={index}
                           onClick={(e) => handleChange(e.target.innerText)}
                        >
                           {action}
                        </Activity>
                     ))}
                  </DropDown>
                  <img
                     src={verticalMenu}
                     alt="back"
                     style={{
                        position: 'absolute',
                        width: '40px',
                        right: 0,
                        filter:
                           'invert(27%) sepia(11%) saturate(7243%) hue-rotate(207deg) brightness(94%) contrast(88%)',
                        cursor: 'pointer',
                     }}
                     onClick={(e) => setAnchorEl(e.currentTarget)}
                  />
                  <Typography
                     sx={{
                        width: 'max-content',
                        margin: '0 auto',
                        fontWeight: 600,
                        fontSize: 'clamp(1.1rem, 2vw, 1.25rem)',
                        color: '#0F0E0E',
                        marginTop: '1rem',
                     }}
                  >
                     Contacts
                  </Typography>
                  <Typography
                     sx={{
                        width: 'max-content',
                        margin: '0 auto',
                        fontWeight: 600,
                        fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                        color: '#0F0E0E',
                     }}
                  >
                     {`${
                        selector.totalContacts ? selector.totalContacts : 0
                     } contacts`}
                  </Typography>
                  {(action === 'Delete' || action === 'Send Message') && (
                     <Typography
                        sx={{
                           display: 'flex',
                           alignItems: 'center',
                           fontSize: 'clamp(1rem, 1.5vw, 1.1rem)',
                           fontWeight: 600,
                           position: 'relative',
                        }}
                     >
                        <Checkbox
                           checked={itemsChecked}
                           onClick={selectItem}
                           {...label}
                        />{' '}
                        Select All
                        {action === 'Delete' && (
                           <img
                              src={trash}
                              alt="delete"
                              style={{
                                 position: 'absolute',
                                 right: '40px',
                                 top: '50%',
                                 transform: 'translateY(-50%)',
                                 cursor: 'pointer',
                              }}
                              onClick={() => {
                                 setIsDelete(true);
                              }}
                           />
                        )}
                        {action === 'Send Message' && (
                           <img
                              src={msgIcon}
                              alt="message"
                              style={{
                                 position: 'absolute',
                                 right: '40px',
                                 top: '50%',
                                 transform: 'translateY(-50%)',
                                 cursor: 'pointer',
                              }}
                              onClick={() => handleSendMessage()}
                           />
                        )}
                        <FaTimes
                           color="#000"
                           style={{
                              position: 'absolute',
                              right: '10px',
                              top: '50%',
                              transform: 'translateY(-50%)',
                              cursor: 'pointer',
                           }}
                           onClick={() => setAction('')}
                        />
                     </Typography>
                  )}
                  <FormControl
                     sx={{
                        width: '100%',
                        position: 'relative',
                        marginTop: action === 'Delete' ? '1rem' : '2rem',
                     }}
                  >
                     <FaSearch
                        color="#374DA0"
                        style={{
                           zIndex: 2,
                           position: 'absolute',
                           top: '50%',
                           left: '20px',
                           transform: 'translateY(-50%)',
                           fontSize: '0.9rem',
                        }}
                     />
                       {search !== '' && (
                                            <FaTimes
   color="#374DA0"
   style={{
      zIndex: 2,
      position: 'absolute',
      top: '50%',
      right: '20px',
      transform: 'translateY(-50%)',
      fontSize: '0.9rem',
      cursor: 'pointer',
   }}
   onClick={() => setSearch('')}
/>  
)}
                     <TextInput
                        value={search}
                        fullWidth
                        placeholder="search contacts"
                        InputProps={{
                           style: {
                              color: '#374DA0',
                              borderRadius: '25px',
                              paddingLeft: '35px',
                              fontSize: '1rem',
                           },
                        }}
                        onChange={(event) => handleSearch(event)}
                     />
                  </FormControl>
                  <Box
                     sx={{
                        marginTop: '2rem',
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '4px', // Control space between buttons
                        '@media (max-width:475px)': {
                           flexDirection: 'column',
                           alignItems: 'center',
                           rowGap: '15px',
                        },
                     }}
                  >
                     <FlatButton
                        variant="outlined"
                        onClick={() => navigate('/contacts/classify')}
                     >
                        <img
                           src={classifyImg}
                           alt="classify"
                           style={{
                              marginRight: '3px', // Reduced space between icon and text
                              width: '18px', // Adjust width if necessary
                              height: '20px',
                           }}
                        />
                        Classified Contacts
                     </FlatButton>

                     <FlatButton
                        variant="outlined"
                        onClick={() => setIsOpen(true)}
                     >
                        <img
                           src={infoImg}
                           alt="view info"
                           style={{
                              marginRight: '6px',
                              width: '20px',
                              height: '20px',
                           }}
                        />
                        View Pricing Info
                     </FlatButton>

                     <FlatButton
                        variant="outlined"
                        onClick={handleExport}
                        disabled={loading === 'pending'}
                        startIcon={
                           loading === 'pending' ? (
                              <CircularProgress size={20} color="inherit" />
                           ) : (
                              <FaDownload
                                 style={{
                                    fontSize: '20px',
                                    marginRight: '6px',
                                 }}
                              />
                           )
                        }
                     >
                        <span className="export-text">Export Contacts</span>
                     </FlatButton>
                  </Box>

                  <Box
                     sx={{
                        marginTop: '2rem',
                        height: '42vh',
                        overflowY: 'auto',
                     }}
                     id="scrollableDiv"
                  >
                     <InfiniteScroll
                        dataLength={selector.contacts.length}
                        next={loadmore}
                        hasMore={selector.hasMore}
                        loader={
                           <h4 style={{ textAlign: 'center' }}>Loading...</h4>
                        }
                        scrollableTarget="scrollableDiv"
                        endMessage={
                           <p style={{ textAlign: 'center' }}>
                              Yay! You have seen it all
                           </p>
                        }
                     >
                        {sorted.length > 0 && action === '' ? (
                           sorted.map((item, index) => (
                              <Card
                                 sx={{
                                    display: 'flex',
                                    paddingY: '10px',
                                    paddingLeft: '70px',
                                    marginBottom: '10px',
                                    paddingRight: '15px',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    position: 'relative',
                                    '&:hover': {
                                       boxShadow:
                                          '0px 0px 1px 0px rgba(0,0,0,0.1)',
                                    },
                                 }}
                                 key={item.id}
                                 onClick={() =>
                                    navigate(`/contacts/user/${item.id}`)
                                 }
                              >
                                 <Box
                                    sx={{
                                       position: 'absolute',
                                       left: '15px',
                                       top: '50%',
                                       transform: 'translateY(-50%)',
                                       width: '40px',
                                       height: '40px',
                                       borderRadius: '50%',
                                       backgroundColor: ' #D7DBEC',
                                       display: 'flex',
                                       alignItems: 'center',
                                       justifyContent: 'center',
                                    }}
                                 >
                                    <Typography
                                       sx={{
                                          fontWeight: '600',
                                          fontSize:
                                             'clamp(0.9rem, 1.5rem, 1.1rem)',
                                       }}
                                    >
                                       {item.fullName
                                          ?.split('')[0]
                                          ?.toUpperCase()}
                                    </Typography>
                                 </Box>
                                 <Box key={index}>
                                    <Typography
                                       sx={{
                                          fontSize: '1rem',
                                          fontWeight: 600,
                                       }}
                                    >
                                       {item.fullName}
                                    </Typography>
                                    <Typography
                                       sx={{
                                          fontSize: '1rem',
                                          fontWeight: 600,
                                          fontFamily:
                                             "'Raleway', 'Montserrat', sans-serif",
                                       }}
                                    >
                                       {item.number}
                                    </Typography>
                                 </Box>
                              </Card>
                           ))
                        ) : (sorted.length > 0 && action === 'Delete') ||
                          (sorted.length > 0 && action === 'Send Message') ? (
                           sorted.map((item, index) => (
                              <Card
                                 sx={{
                                    display: 'flex',
                                    paddingY: '10px',
                                    paddingLeft: '15px',
                                    marginBottom: '10px',
                                    cursor: 'pointer',
                                    '&:hover': {
                                       boxShadow:
                                          '0px 0px 1px 0px rgba(0,0,0,0.75)',
                                    },
                                 }}
                                 key={index}
                              >
                                 <Checkbox
                                    value={item.id}
                                    checked={checkedList.includes(item.id)}
                                    onClick={handleCheckboxClick}
                                    {...label}
                                 />
                                 <Box sx={{ paddingLeft: '10px' }}>
                                    <Typography
                                       sx={{
                                          fontSize: '1rem',
                                          fontWeight: 600,
                                          fontFamily:
                                             "'Raleway', 'Montserrat', sans-serif",
                                       }}
                                    >
                                       {item.fullName}
                                    </Typography>
                                    <Typography
                                       sx={{
                                          fontSize: '1rem',
                                          fontWeight: 600,
                                          fontFamily:
                                             "'Raleway', 'Montserrat', sans-serif",
                                       }}
                                    >
                                       {item.number}
                                    </Typography>
                                 </Box>
                              </Card>
                           ))
                        ) : search != '' && sorted.length === 0 ? (
                           <Typography
                              sx={{
                                 textAlign: 'center',
                                 fontSize: 'clamp(1rem, 2vw, 1.2rem)',
                                 fontWeight: '500',
                                 fontFamily:
                                    "'Raleway', 'Montserrat', sans-serif",
                                 marginTop: '2rem',
                              }}
                           >
                              Oops No Search result!
                           </Typography>
                        ) : (
                           <Typography
                              sx={{
                                 textAlign: 'center',
                                 fontSize: 'clamp(1rem, 2vw, 1.2rem)',
                                 fontWeight: 500,
                                 marginTop: '2rem',
                              }}
                           >
                              Loading...
                           </Typography>
                        )}
                     </InfiniteScroll>
                  </Box>
               </Box>
            </Card>
         ) : (
            <NoContacts />
         )}
         <Dialog onClose={() => setAdd(false)} open={add}>
            <AddContact
               setDialogState={setDialogState}
               directText={'/contacts/add-direct'}
               vcfText={'/contacts/add-vcf'}
               copyPasteText={'/contacts/add-copypaste'}
            />
         </Dialog>
      </Container>
   );
};

export default AllContacts;
